<template>
  <div class="form-group froala-container">
    <div class="froala-label">
    <label class="text-monospace text-bold" :style="field.styles">
      {{ field.label }}
    </label>

      <button class="button" @click="handleThumbnailClass">
        <span v-if="this.thumbnailStatus">Full images view</span>
        <span v-else>thumbnail view</span>
      </button>
    </div>

    <div :class="this.thumbnailStatus ? 'froala-thumbnails' : ''">
      <froala
        :tag="'textarea'"
        :config="config"
        v-model="field.value"
        :name="field.name"
        :cassielid="cassielid"
        :widgetName="widgetName"
        :entityName="entityName"
      >
      </froala>
    </div>

    <div id="tuiContainer" class="imageEditor" ref="tuiImageEditor"></div>
  </div>
</template>
<style>
  .froala-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px
  }
.imageEditor {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
}
.froala-container {
  background: #fff;
  padding: 26px 25px;
}
.fr-img-wrap {
  display: block;
}
.froala-container p {
  margin-bottom: 1rem !important;
}
.froala-container h2,
.froala-container h3 {
  line-height: inherit;
}
.froala-container h3 {
  font-size: 1.25rem;
}
.fr-element a {
  color: #3c8dbc !important;
}

.divTOC ol {
  margin: 10px 2rem;
}
.fr-toolbar.fr-sticky-on {
  top: 70px !important;
}

.fr-view span.fr-img-caption.fr-dib.fr-fil{
  width: 100% !important;
}

.froala-thumbnails .fr-view img,
.froala-thumbnails .fr-view .fr-img-caption .fr-img-wrap img{
  max-width: 300px !important;
  max-height: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.froala-thumbnails .fr-view span.fr-img-caption.fr-dib.fr-fil{
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
<script>
import $ from "jquery";

import FroalaEditor from "froala-editor";
import ImageEditor from "tui-image-editor";
let plugins = process.env.VUE_APP_FROALA_PLUGINS.split(",");
let direction = process.env.VUE_APP_FROALA_DIRECTION;
let key = process.env.VUE_APP_FROALA_KEY;

const editorDefaultOptions = {
  cssMaxWidth: 700,
  cssMaxHeight: 500,
};

export default {
    name: "cassieltextfield",
    mixins: [],
    props: {
        field: {
            type: Object,
        },
        index: {
            type: Number,
        },
        cassielid: {
            type: String,
        },
        widgetName: {
            type: String,
            required: true,
        },
        entityName: {
            type: String,
            required: true,
        },
        includeUi: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Object,
            default() {
                return editorDefaultOptions;
            },
        }
    },
    data() {
        return {
            config: {
                events: {
                    'initialized': function () {
                        // $(".fr-img-wrap, .fr-img-wrap img").on('click', function () {
                        //     setTimeout(function () {
                        //         $(".fr-popup").addClass("fr-active");
                        //
                        //     }, 100);
                        // })
                    }
                },
                key,
                direction,
                dragInline: false,
                imageDefaultWidth: 0,
                fileAllowedTypes: ["application/pdf", "application/msword"],
                heightMin: 200,
                htmlExecuteScripts: false,
                pastePlain: true,
                htmlAllowedTags: [".*"],
                htmlRemoveTags: [""],
                htmlDoNotWrapTags: ["script", "style", "img", "cassielEmbed"],
                lineBreakerTags: ["img", "p"],
                htmlAllowedAttrs: ["vdb_params", ".*"],
                widget: this.widgetName,
                entity: this.entityName,
                cassielId: this.cassielid,
                imageEditButtons: [
                    "imageCaption",
                    "imageReplace",
                    "imageAlign",
                    "imageRemove",
                    "imageLink",
                    "linkOpen",
                    "linkEdit",
                    "linkRemove",
                    "-",
                    "imageDisplay",
                    "imageStyle",
                    "imageAlt",
                    "imageSize",
                    "imageInfo",
                ],
                toolbarButtons: {
                    moreText: {
                        buttons: [
                            "imageInfo",
                            "bold",
                            "italic",
                            "underline",
                            "strikeThrough",
                            "subscript",
                            "superscript",
                            "fontFamily",
                            "fontSize",
                            "textColor",
                            "backgroundColor",
                            "inlineClass",
                            "inlineStyle",
                            "clearFormatting",
                        ],
                        align: "left",
                        buttonsVisible: 3,
                    },
                    moreParagraph: {
                        buttons: [
                            "alignRight",
                            "alignCenter",
                            "formatOL",
                            "formatUL",
                            "alignLeft",
                            "alignJustify",
                            "paragraphFormat",
                            "paragraphStyle",
                            "lineHeight",
                            "outdent",
                            "indent",
                            "quote",
                        ],
                        align: "left",
                        buttonsVisible: 4,
                    },
                    moreMisc: {
                        buttons: [
                            "undo",
                            "redo",
                            "fullscreen",
                            "selectAll",
                            "html",
                            "help",
                        ],
                        align: "right",
                        buttonsVisible: 2,
                    },
                    moreRich: {
                        buttons: [
                            "insertLink",
                            "emoticons",
                            "fontAwesome",
                            "specialCharacters",
                            "embedly",
                            "insertHR",
                        ].concat(plugins),
                        align: "left",
                        buttonsVisible: 3,
                    },
                },
            },
            model: "Edit Your Content Here!",
            includeUIOptions: {
                includeUI: {
                    menu: ["crop", "flip"],
                    initMenu: "crop",
                    loadImage: {
                        path: "",
                        name: "SampleImage",
                    },
                },
            },
            thumbnailStatus: false
        };
    },
    mounted() {
        const $this = this;

        FroalaEditor.DefineIcon("imageInfo", {NAME: "info", SVG_KEY: "advancedImageEditor"});
        FroalaEditor.RegisterCommand("imageInfo", {
            title: "Edit Image",
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                let t = this;
                let o = t.image.get();

                $this.includeUIOptions["includeUI"]["loadImage"]["path"] = this.image.get().attr("src");
                // $this.includeUIOptions["includeUI"]["loadImage"]["path"] = "/2.jpeg";

                let options = editorDefaultOptions;
                if ($this.includeUi) {
                    options = Object.assign($this.includeUIOptions, $this.options);
                }
                $this.editorInstance = new ImageEditor(
                    $this.$refs.tuiImageEditor,
                    options
                );

                document.getElementsByClassName(
                    "tui-image-editor-header-buttons"
                )[0].innerHTML =
                    '<button class="tui-editor-cancel-btn" data-cmd="cancel_tui_image">Cancel</button> <button class="tui-editor-save-btn">Save</button>';

                document
                    .getElementsByClassName("tui-editor-cancel-btn")[0]
                    .addEventListener("click", function (e) {
                        e.preventDefault();
                        $("#tuiContainer").hide();
                    });

                document
                    .getElementsByClassName("tui-editor-save-btn")[0]
                    .addEventListener("click", function (e) {
                        e.preventDefault();
                        let s = $this.editorInstance;
                        let i = true;
                        let n = null;
                        $this.handleSave(s, t, o, i, n);
                        $this.g();
                    });
                $("#tuiContainer").show();
            },
        });
    },
    created() {},
    destroyed() {
        Object.keys(this.$listeners).forEach((eventName) => {
            this.editorInstance.off(eventName);
        });
        this.editorInstance.destroy();
        this.editorInstance = null;
    },
    methods: {
        async handleSave(e, t, i) {
            for (
                var a = e.toDataURL(), d = atob(a.split(",")[1]), r = [], s = 0;
                s < d.length;
                s++
            )
                r.push(d.charCodeAt(s));
            const u = new Blob([new Uint8Array(r)], {
                type: "image/png",
            });

            var img = new Image();
            img.src = URL.createObjectURL(u);

            let $this = this;
            img.onload = async function() {
                i[0].src = await $this.saveBlobAjax(u, img.width, img.height);
                t.image.edit(i);
            };
            img.remove();
        },
        async saveBlobAjax(output, width, height) {
            const token = this.$store.getters["auth/getToken"];
            let headers = {};
            if (token) {
                headers["X-AUTH-TOKEN"] = token;
            }

            let data = new FormData();

            data.append("file", output);
            data.append("width", width +"px");
            data.append("height", height +"px");
            data.append("filter", "default");
            data.append("widget", "article");
            data.append("alt", "");

            return await this.$http({
                url: this.$apiList.uploadFileApi,
                method: "POST",
                data: data,
                headers: headers,
            })
                .then((response) => {
                    if (response.data.filePath === undefined) {
                        return 'error';
                    }
                    return process.env.VUE_APP_FILES_BASE_URL + response.data.filePath;
                })
                .catch((error) => {
                    console.log(error)
                    return 'error';
                });
        },
        g() {
            $("#tuiContainer").hide();
        },
        addEventListener() {
            Object.keys(this.$listeners).forEach((eventName) => {
                this.editorInstance.on(eventName, (...args) =>
                    this.$emit(eventName, ...args)
                );
            });
        },
        getRootElement() {
            return this.$refs.tuiImageEditor;
        },
        invoke(methodName, ...args) {
            let result = null;
            if (this.editorInstance[methodName]) {
                result = this.editorInstance[methodName](...args);
            } else if (methodName.indexOf(".") > -1) {
                const func = this.getMethod(this.editorInstance, methodName);

                if (typeof func === "function") {
                    result = func(...args);
                }
            }

            return result;
        },
        getMethod(instance, methodName) {
            const {first, rest} = this.parseDotMethodName(methodName);
            const isInstance = instance.constructor.name !== "Object";
            const type = typeof instance[first];
            let obj;

            if (isInstance && type === "function") {
                obj = instance[first].bind(instance);
            } else {
                obj = instance[first];
            }

            if (rest.length > 0) {
                return this.getMethod(obj, rest);
            }

            return obj;
        },
        parseDotMethodName(methodName) {
            const firstDotIdx = methodName.indexOf(".");
            let firstMethodName = methodName;
            let restMethodName = "";

            if (firstDotIdx > -1) {
                firstMethodName = methodName.substring(0, firstDotIdx);
                restMethodName = methodName.substring(
                    firstDotIdx + 1,
                    methodName.length
                );
            }

            return {
                first: firstMethodName,
                rest: restMethodName,
            };
        },
        handleThumbnailClass(e){
            e.preventDefault();
            this.thumbnailStatus = !this.thumbnailStatus;
        }
    },
};
</script>
