<template>
  <div class="form-group">
    <form method="post">
    <input
        :formaction="url + field.api"
        :id="field.name"
        :name="field.name"
        class="form-control"
        type="button"
        value="Export"
        @click="exportData"
   />

    </form>
  </div>
</template>
<style></style>
<script>
export default {
  name: "cassielexportbutton",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    exportData: function () {
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      let bodyFormData = new FormData();
      for(var i=0 ; i< this.field.fields.length; i++) {
        bodyFormData.append(this.field.fields[i], document.querySelector("input[name='"+this.field.fields[i]+"']").value);
      }

      this.$http({
        url:
            process.env.VUE_APP_CMS_BACKEND_BASE_URL +
            "" +
            this.field.api,
        method: "POST",
        data: bodyFormData,
        headers: headers,
      })
          .then((response) => {
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent+= response.data;
            var link = window.document.createElement("a");
            link.setAttribute("href", "" + encodeURI(csvContent));
            link.setAttribute("download", "Articles Export ("+date+").csv");
            link.click();
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
};
</script>
