<template>
  <div>
    <div class="form-group checkbox">
      <label :for="field.name" class="text-monospace text-bold text-capitalize">
        <input
          type="checkbox"
          :id="field.name"
          v-model="field.value"
          :readonly="field.disable === 1"
          :name="field.name"
          class=""
        />
        {{ field.label }}
      </label>
      <span :id="field.name + '_error'" class="text-danger b-toast-danger" />
    </div>
    <div
      class="form-group"
      v-if="field.controle === '1'"
      :style="
        field.label !== ''
          ? 'background: white; padding: 20px; border-radius: 10px;  border: 2px solid teal;'
          : ''
      "
    >
      <label
        :for="field.name"
        class="text-monospace text-bold text-capitalize"
        style="color: teal; font-size: 18px; margin: 0 !important"
      >
        {{ datafield.label }}
      </label>
      <hr v-if="datafield.label !== ''" />
      <div v-for="(data, dataindex) in datafield.data" :key="dataindex">
        <div v-for="(field, fieldindex) in data" :key="fieldindex">
          <CassielFieldsRender
            :parent="dataindex"
            :index="fieldindex"
            :field="field"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cassielcheckbox",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  components: {
    CassielFieldsRender: () => import("./cassielFieldsRender.vue"),
  },
  data() {
    return {
      status: "not_accepted",
      datafield: [],
    };
  },
  computed: {},
  created() {
    this.field.value = this.field.value === "1" || this.field.value === true;
    this.setDataFields();
  },
  methods: {
    setDataFields() {
      if (this.field.data) {
        if (!this.field.value) {
          this.datafield = this.field.data["false"];
        } else {
          this.datafield = this.field.data["true"];
        }
      }
    },
  },
  watch: {
    "field.value": {
      deep: true,
      handler() {
        this.setDataFields();
      },
    },
  },
};
</script>

<style>
input.larger {
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:checked + label::after {
  content: none !important;
}

input[type="checkbox"] {
  line-height: 2.1ex;
}

input[type="radio"],
input[type="checkbox"] {
  position: absolute;
  left: auto;
}

input[type="checkbox"] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 5px;
  margin-left: 20px;
}

input[type="checkbox"] + label::before {
  content: none !important;
}

.checkbox label,
.radio label {
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}
</style>
