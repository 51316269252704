<template>
  <div class="form-group">
    <label
      class="text-monospace text-bold text-capitalize"
      :style="field.styles"
    >
      {{ field.label }}
    </label>
    <input
      v-model="field.value"
      :readonly="field.disable === 1"
      type="color"
      :name="field.name"
      class=""
      style="margin-left: 10px"
      :placeholder="field.placeholder"
    />
    <span :id="field.name + '_error'" class="text-danger b-toast-danger"></span>
  </div>
</template>

<script>
export default {
  name: "cassielcolorpicker",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
};
</script>
