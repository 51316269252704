<template>
  <div class="form-group">
    <div class="col-sm-12">
      <label
        class="text-monospace text-bold text-capitalize"
        :style="field.styles"
      >
        {{ field.label
        }}<span v-if="field.required" class="text-danger">*</span>
      </label>
      <vs-select
        :disabled="field.disabled"
        @input="myChangeEvent"
        v-model="field.value"
        :name="field.name"
        :id="field.name + index + 'Id'"
        :multiple="field.multiple"
        :placeholder="field.placeholder"
        class="form-control"
        autocomplete
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.text"
          v-for="(item, index) in field.options"
        />
      </vs-select>
      <template v-if="field.options && field.options.length > 0">
        <input
          type="hidden"
          :id="`${field.name}Id${index}`"
          :name="`${field.nameId}`"
          :value="field.value"
        />
      </template>
      <span
        :id="field.name + '_error'"
        class="b-toast-danger alert alert-danger cassiel-error"
        style="display: none"
      />
    </div>
  </div>
</template>
<style>
/* .con-select.form-control {
  border: 1px solid #c1c6e5 !important;
  border-bottom: 1px solid #586bb2 !important;
} */

/* .con-select.form-control input {
  border: none !important;
} */
</style>

<script>
let parentId = 0;
export default {
  name: "cassielDropDown",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    formConfiguration: {
      type: [Array, Object],
    },
    index: {
      type: Number,
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    async init() {
      if (this.field.nameId === undefined) {
        this.field.nameId = this.field.name.replace("[]", "") + "Id[]";
      }
      if (this.field.predefined) {
        this.settingsPredefined().then((data) => {
          this.field.multiple = data.multiple;
          this.field.placeholder = data.placeholder;
          this.field.options = data.options || [];
          this.$forceUpdate();
        });
      } else {
        this.settings().then((data) => {
          this.field.multiple = data.multiple;
          this.field.placeholder = data.placeholder;
          this.field.options = data.options || [];
          this.$forceUpdate();
        });
      }

      if (this.field.value && Array.isArray(this.field.value)) {
        this.field.value = this.field.value[0];
      }
    },

    async settings() {
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }

      if (this.field.parentId === undefined) {
        return this.$http({
          url: process.env.VUE_APP_CMS_BACKEND_BASE_URL + "" + this.field.api,
          method: "POST",
          dataTpe: "json",
          headers: headers,
        }).then((response) => {
          return {
            width: "100%",
            placeholder: this.field.placeholder,
            allowClear: true,
            tags: this.field.tags,
            multiple: this.field.multiple,
            options: response.data.results,
          };
        });
      } else {
        parentId = this.field.parentId;
        let bodyFormData = new FormData();
        bodyFormData.append("id", parentId);
        return this.$http({
          url: process.env.VUE_APP_CMS_BACKEND_BASE_URL + "" + this.field.api,
          method: "POST",
          dataTpe: "json",
          headers: headers,
          data: bodyFormData,
        }).then((response) => {
          return {
            width: "100%",
            placeholder: this.field.placeholder,
            allowClear: true,
            tags: this.field.tags,
            multiple: this.field.multiple,
            options: response.data.results,
          };
        });
      }
    },

    async settingsPredefined() {
      return {
        width: "100%",
        placeholder: this.field.placeholder,
        allowClear: true,
        tags: this.field.tags,
        multiple: this.field.multiple,
        options: this.field.options,
      };
    },

    myChangeEvent(value) {
      const _self = this;
      this.$emit("valueChanged", value, _self.field.name);
      if (this.field.child !== undefined) {
        const token = this.$store.getters["auth/getToken"];
        let headers = {};
        if (token) {
          headers["X-AUTH-TOKEN"] = token;
        }
        let bodyFormData = new FormData();
        if (value) {
          bodyFormData.append("id", value);
          this.parentId = value;
        }
        this.$vs.loading();
        this.$http({
          url:
            process.env.VUE_APP_CMS_BACKEND_BASE_URL + "" + this.field.childapi,
          method: "POST",
          data: bodyFormData,
          headers: headers,
        })
          .then((response) => {
            this.$vs.loading.close();
            //in case the formconfiguration is a form
            if (
              this.formConfiguration &&
              Array.isArray(this.formConfiguration)
            ) {
              if (
                this.formConfiguration[1] &&
                this.formConfiguration[1].tabtitle &&
                this.formConfiguration[1].tabtitle.toLowerCase() === "seo"
              ) {
                this.formConfiguration[0].data.forEach((oneField) => {
                  if (oneField.name === _self.field.child) {
                    oneField.options = [];
                    _self.$forceUpdate();
                    oneField.options = response.data.results;
                    oneField.value = null;
                    _self.$forceUpdate();
                  }
                });
              } else {
                for (let i = 0; i < this.formConfiguration.length; i++) {
                  if (this.formConfiguration[i].name === _self.field.child) {
                    this.formConfiguration[i].options = [];
                    this.formConfiguration[i].value = null;
                    this.formConfiguration[i].options = response.data.results;
                    this.formConfiguration[i].value =
                      response.data.results[0].id;
                  }
                }
                _self.$forceUpdate();
              }
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.error(error);
          });
      }
    },
  },
};
</script>
