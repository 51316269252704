<template>
  <div class="form-group">
    <!--    <input  v-bind:id="name"  v-bind:checked="value == true || value == 1" :readonly="disable == 1" type="checkbox"  v-on:input="updateValue($event.target.value)"  v-bind:name="name" class=" larger border-bottom-teal" v-bind:index="index">-->
    <!--    <label v-bind:for="name" class="text-monospace text-bold text-capitalize">{{title}}</label>-->

    <div class="form-group checkboxBox">
      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            v-bind:name="name + index"
            class="everydayInput"
            v-bind:checked="value.length === 7"
            @click="checkDays"
          />
          Everyday
        </label>
        <hr />
      </div>
      <div class="daysList">
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-bind:name="name + index + '[]'"
              class="daysInput"
              value="monday"
              v-bind:checked="value.includes('monday')"
            />
            Monday
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-bind:name="name + index + '[]'"
              class="daysInput"
              value="tuesday"
              v-bind:checked="value.includes('tuesday')"
            />
            Tuesday
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-bind:name="name + index + '[]'"
              class="daysInput"
              value="wednesday"
              v-bind:checked="value.includes('wednesday')"
            />
            Wednesday
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-bind:name="name + index + '[]'"
              class="daysInput"
              value="thursday"
              v-bind:checked="value.includes('thursday')"
            />
            Thursday
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-bind:name="name + index + '[]'"
              class="daysInput"
              value="friday"
              v-bind:checked="value.includes('friday')"
            />
            Friday
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-bind:name="name + index + '[]'"
              class="daysInput"
              value="saturday"
              v-bind:checked="value.includes('saturday')"
            />
            Saturday
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-bind:name="name + index + '[]'"
              class="daysInput"
              value="sunday"
              v-bind:checked="value.includes('sunday')"
            />
            Sunday
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cassielscheduledayscheckbox",
  mixins: [],
  props: {
    name: {
      type: String,
    },
    value: {
      type: Array,
    },
    index: {
      type: Number,
    },
    title: {
      type: String,
    },
    disable: {
      type: String,
    },
  },

  components: {},
  data() {
    return {
      status: "not_accepted",
    };
  },
  computed: {},
  methods: {
    updateValue: function (value) {
      this.$emit("updatevalue", this.name, this.index, value);
    },
    checkDays: function (event) {
      if (event.target.checked) {
        var days = [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ];
        var array = days.filter((val) => !this.value.includes(val));
        this.value = array;
      } else {
        this.value = [];
      }
      this.updateValue(this.value);
    },
  },
};
</script>

<style>
input.larger {
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:checked + label::after {
  content: none !important;
}

input[type="checkbox"] {
  line-height: 2.1ex;
}

input[type="radio"],
input[type="checkbox"] {
  position: absolute;
  left: auto;
}

input[type="checkbox"] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 5px;
  margin-left: 20px;
}

input[type="checkbox"] + label::before {
  content: none !important;
}

/* .checkbox label, .radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
} */

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

hr {
  background-color: black !important;
}
</style>
