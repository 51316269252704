<template>
  <div class="form-group">
    <label class="text-monospace text-bold text-capitalize">{{
      field.label
    }}</label>
    <vue-dropzone
      ref="myVueDropzone"
      @vdropzone-sending="sendingEvent"
      @vdropzone-removed-file="removedfile"
      @vdropzone-success="addInput"
      @vdropzone-mounted="vdropzoneMount"
      id="dropzone"
      :options="dropzoneOptions"
    />
    <div ref="inputs"></div>
  </div>
</template>
<style>
.vue-dropzone {
  border: 2px dashed teal;
  border-radius: 5px;
}
</style>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "cassieldropzone",
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },

  data: function () {
    return {
      headers: {},
      dropzoneOptions: {
        autoDiscover: false,
        autoProcessQueue: true,
        url:
          process.env.VUE_APP_CMS_BACKEND_BASE_URL +
          this.$apiList.uploadFileApi,
        thumbnailWidth: 150,
        uploadMultiple: false,
        headers: { "X-AUTH-TOKEN": this.$store.getters["auth/getToken"] },
        dictDefaultMessage:
          "<i class='mdi mdi-cloud-upload' style='font-size: 25px'></i><br/>Drop your files",
        addRemoveLinks: true,
        acceptedFiles: "image/*",
      },
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append("filter", this.field.filter);
      formData.append("width", this.field.width);
      formData.append("height", this.field.height);
      formData.append("widget", this.field.widgetName);
    },
    addInput(file, response) {
      let input =
        '<input type="hidden" name="' +
        this.field.widgetName +
        '|images[]" id="' +
        file.name.split(".")[0] +
        '" value="' +
        response.filePath +
        '">';
      this.$refs.inputs.innerHTML = this.$refs.inputs.innerHTML + input;
    },
    removedfile: function (file) {
      let id = file.name.split(".")[0];
      let element = document.getElementById(id);
      element.remove();
    },
    vdropzoneMount() {
      for (let i = 0; i < this.field.value.length; i++) {
        let url =
          process.env.VUE_APP_PREVIEW_BASE_URL + "" + this.field.value[i].path;
        const filename = url.substring(url.lastIndexOf("/") + 1);
        let file = {
          size: 0,
          name: filename,
          type: "image/" + url.match(/\.(jpeg|jpg|gif|png)$/)[1],
        };
        this.$refs.myVueDropzone.manuallyAddFile(file, url);
        const inputId = filename.split(".").slice(0, -1).join(".");
        let input =
          '<input type="hidden" name="' +
          this.field.widgetName +
          '|images[]" id="' +
          inputId +
          '" value="' +
          this.field.value[i].path +
          '">';
        this.$refs.inputs.innerHTML = this.$refs.inputs.innerHTML + input;
      }
    },
  },
};
</script>
