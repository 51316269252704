<template>
  <div class="form-group">
    <label
        class="text-monospace text-bold text-capitalize"
        :style="field.styles">{{ field.label }}
    </label>
    <VueEmoji
        ref="emoji"
        width="100%"
        :name="field.name+'Texterea'"
        :placeholder="field.placeholder"
        :index="index"
        v-model="field.value"
    />
    <input
        type="hidden"
        :id="field.name+'Id'"
        :name="field.name"
        v-model="field.value"
    />
  </div>
</template>
<style>
.emoji-wysiwyg-editor {
  border-bottom: 1px solid #008080;
  background-color: #fff;
  direction: rtl;
  padding-left: 40px;
  padding-right: 5px !important;
}

.emoji-picker-icon {
  left: 10px !important;
}

body {
  text-align: inherit !important;
}
</style>
<script>
import VueEmoji from 'emoji-vue'

export default {
  name: "cassieltextfieldemoji",
  mixins: [],
  props: {
    field: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  components: {
    VueEmoji
  }
};
</script>