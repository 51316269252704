<template>
  <div class="form-group">
    <link
      rel="stylesheet"
      href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css"
    />
    <div class="col-sm-12">
      <label
        class="text-monospace text-bold text-capitalize"
        :style="field.styles"
      >
        {{ field.label
        }}<span v-if="field.required" class="text-danger">*</span>
      </label>
      <multiselect
        :loading="loading"
        v-model="field.value"
        :allow-empty="field.required"
        :options="field.options"
        :multiple="field.multiple"
        :hide-selected="true"
        :internal-search="false"
        :close-on-select="false"
        :clear-on-select="true"
        :preserve-search="true"
        placeholder="-- SELECT --"
        label="text"
        track-by="id"
        :preselect-first="true"
        @search-change="myChangeEvent"
        open-direction="bottom"
        :taggable="true"
        @tag="addTag"
        tag-placeholder="Add this as new tag"
        tag-position="bottom"
      >
        <span slot="noResult"
          >Oops! No elements found. Consider changing the search query.</span
        >
      </multiselect>
      <br />
      <span
        :id="field.name + '_error'"
        class="b-toast-danger alert alert-danger cassiel-error"
        style="display: none"
      ></span>
    </div>
  </div>
</template>
<style>
.select2-container--default .select2-selection--multiple {
  border-bottom: 1px solid teal;
}

.select2-container {
  width: 100% !important;
}
</style>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "cassielMultipleSelectfield",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    formConfiguration: {
      type: [Array, Object],
    },
    index: {
      type: Number,
    },
  },

  components: {
    Multiselect,
  },

  data() {
    return {
      loading: false,
    };
  },

  async created() {
    await this.init();
  },

  methods: {
    async init() {
      if (Array.isArray(this.field.value)) {
        this.field.options.forEach((oneOption) => {
          for (let i = 0; i < this.field.value.length; i++) {
            if (oneOption.id === this.field.value[i]) {
              this.field.value[i] = oneOption;
            }
          }
        });
        this.$forceUpdate();
      }
    },

    myChangeEvent: function (query) {
      if (query && query.length >= 2) {
        const token = this.$store.getters["auth/getToken"];
        let headers = {};
        if (token) {
          headers["X-AUTH-TOKEN"] = token;
        }
        this.loading = true;
        this.$http({
          url: `${process.env.VUE_APP_CMS_BACKEND_BASE_URL}${this.field.api}?q=${query}`,
          method: "POST",
          headers: headers,
        })
          .then((response) => {
            this.field.options = response.data.results;
            this.$forceUpdate();
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    addTag(newTag) {
      const tag = {
        id: newTag,
        keyword: newTag,
        text: newTag,
      };
      this.field.options.push(tag);
      this.field.value.push(tag);
      this.$forceUpdate();
      this.loading = false;
    },
  },
};
</script>
