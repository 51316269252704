<template>
  <div>
    <div
      v-if="debug === 'false'"
      style="
        border: 1px solid black;
        background-color: black;
        color: greenyellow;
        max-height: 150px;
        overflow: scroll;
      "
    >
      {{ field }}
    </div>

    <cassieltextfield
      v-if="['text', 'password', 'email'].includes(field.type)"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielexportbutton
      v-else-if="field.type === 'exportButton'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieltextfieldemoji
      v-else-if="field.type === 'textemoji'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieldisplayfield
      v-else-if="field.type === 'display'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielcolorpicker
      v-else-if="field.type === 'colorpicker'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieluploadpdf
      v-else-if="field.type === 'uploadpdf'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieltextarea
      v-else-if="field.type === 'cassieltextarea'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielseo
      v-else-if="field.type === 'seo'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielsnippet
      v-else-if="field.type === 'snippet'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielnumberfield
      v-else-if="field.type === 'number'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielhiddenfield
      v-else-if="field.type === 'hidden'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielcheckbox
      v-else-if="field.type === 'checkbox'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielscheduledayscheckbox
      v-else-if="field.type === 'scheduledays'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielMultipleSelectfield
      v-else-if="field.type === 'multipleSelect'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielDropDown
      v-else-if="field.type === 'dropdown'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      @valueChanged="valueChanged"
      :parentIndex="parentIndex"
    />

    <cassieldatetime
      v-else-if="field.type === 'datetime'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieltime
      v-else-if="field.type === 'time'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieltexteditor
      v-else-if="
        field.type === 'cassieltexteditor' || field.type === 'textarea'
      "
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
      :cassielid="cassielid"
      :widgetName="widgetName"
      :entityName="entityName"
    />

    <cassielcropie
      v-else-if="field.type === 'cassielimagecroppie'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieldropzone
      v-else-if="field.type === 'cassieldropzone'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieldropplace
      v-else-if="field.type === 'cassieldropplace'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassielDropplaceMenu
      v-else-if="field.type === 'cassielDropplaceMenu'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassiellistfields
      v-else-if="field.type === 'cassiellistfields'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <cassieldatatable
      v-else-if="field.type === 'cassieldatatable'"
      :field="field"
      :formConfiguration="formConfiguration"
      :index="index"
      :parentIndex="parentIndex"
    />

    <div v-else-if="field.type === 'manyToOne'" class="manytoone-container">
      <h1 class="mb-2 manytoone-h1">
        {{ field.label }}
        <span v-if="field.required" class="text-danger">*</span>
      </h1>
      <div v-if="field.reorder === 'false'">
        <div
          v-for="(data, dataindex) in field.data"
          v-bind:key="dataindex"
          class="manytoone-children"
          style=""
        >
          <vs-button
            v-on:click="removeRow($event, field, dataindex)"
            class="cassiel-droped-record-remove-row"
          >
            Delete
          </vs-button>
          <div class="fa fa-angle-down cassiel-icon-arrow" @click="handleChildDropDown"></div>
          <div>
            <div v-for="(field, fieldindex) in data" :key="fieldindex" :data-index="fieldindex" class="children-container">
              <cassielFieldsRender
                :parentIndex="dataindex"
                :index="fieldindex"
                :field="field"
                :formConfiguration="formConfiguration"
                @addrow="addRow($event, field, dataindex)"
                @removerow="removeRow"
                :cassielid="cassielid"
                :widgetName="widgetName"
                :entityName="entityName"
              />
            </div>
          </div>
        </div>
      </div>




      <draggable
        v-if="field.reorder === 'true'"
        :list="field.data"
        draggable=".item"
        style=""
        @change="renameField"
        handle=".handle"
      >
        <div
          v-for="(data, dataindex) in field.data"
          class="item"
          v-bind:key="dataindex"
          style="border-radius: 5px"
        >
          <vs-button
            v-on:click="removeRow($event, field, dataindex)"
            class="cassiel-droped-record-remove-row"
          >
            Delete
          </vs-button>
          <div class="fa fa-arrows-v cassiel-droped-record-arrowsbtn handle"></div>
          <div class="fa fa-angle-down cassiel-icon-arrow" @click="handleParentDropDown"></div>
          <div
            v-for="(field, fieldindex) in data"
            :key="fieldindex"
            class="manytoone-main-headers"
            style=""
          >
            <cassielFieldsRender
              :parentIndex="dataindex"
              :index="fieldindex"
              :field="field"
              :formConfiguration="formConfiguration"
              @addrow="addRow($event, field, dataindex)"
              @removerow="removeRow"
              :cassielid="cassielid"
              :widgetName="widgetName"
              :entityName="entityName"
              @renamefield="renameField"
            />
          </div>
        </div>
      </draggable>




      <button
        v-on:click="addRow($event, field, parentIndex)"
        style=""
        class="btn btn-primary btn-rounded"
      >
        <i class="mdi mdi-plus"></i>
        Add {{ field.button }}
      </button>
    </div>

  </div>
</template>
<style>
.manytoone-container {
  margin-bottom: 30px;
  padding-top: 30px;
  background: #e8eaf6;
  position: relative;
}

.manytoone-container .manytoone-container {
  background: #fff;
  margin-top: 0;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 17px;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.manytoone-main-headers.active > div > .manytoone-container{
  max-height: 5000px;
}
.manytoone-container > button {
  margin-top: 25px;
  margin-left: 20px;
  margin-bottom: 25px;
}
.manytoone-container .manytoone-container > button {
  margin-left: 0;
  margin-bottom: 25px;
  margin-top: 25px;
}
.vs-button-danger {
  position: absolute;
  top: 17px;
  right: 10px;
  margin: 0;
  background: transparent !important;
  color: #e41c42 !important;
  font-size: 18px;
  font-weight: 400;
}
.manytoone-h1 {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 25px;
  color: #586bb2;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 15px;
}
.manytoone-container .manytoone-container .manytoone-h1 {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 15px;
}
.manytoone-container label {
  color: #202228 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}
.manytoone-container .froala-container {
  padding-left: 0;
  padding-right: 0;
}
.vs-button-primary.vs-button-filled.cassiel-droped-record-remove-row {
  cursor: pointer;
  top: 12px;
  right: 14px;
  font-size: 18px;
  font-weight: 400;
  color: #e41c42;
  background: transparent !important;
  position: absolute;
}
.manytoone-main-headers {
  padding: 5px 20px;
  background-color: #e8eaf6;
}
.manytoone-children {
  padding: 25px 20px 25px;
  border-radius: 0;
  background-color: #fff;
  border-bottom: 1px solid #c1c6e5;
  /* margin-bottom: 25px; */
  position: relative;
  min-height: 87px;
}
.manytoone-children .children-container:not([data-index="0"]){
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.manytoone-children.active .children-container:not([data-index="0"]){
  overflow: visible;
}
.manytoone-children.active .children-container{
  max-height: 2000px;
}
.manytoone-container .manytoone-container .manytoone-children {
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 0;
}
.manytoone-container .item {
  position: relative;
}
.cassiel-droped-record-arrowsbtn {
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 25px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: transparent;
  color: #586bb2;
  border-bottom-color: #586bb2;
  font-family: "Roboto", "GEFlow", sans-serif;
}

  .cassiel-icon-arrow{
    position: absolute;
    z-index: 2;
    top: 60px;
    font-size: 27px;
    right: 7px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
.cassiel-icon-arrow.active {
  transform: rotate(180deg);
}
</style>
<script>
import cassieltextfield from "@/views/edit/Component/cassieltextfield";
import cassieltextarea from "@/views/edit/Component/cassieltextarea";
import cassielseo from "@/views/edit/Component/cassielSeo";
import cassielsnippet from "@/views/edit/Component/cassielsnippet";
import cassieldatetime from "@/views/edit/Component/cassieldatetime";
import cassieltime from "@/views/edit/Component/cassieltime";
import cassielcropie from "@/views/edit/Component/cassielcropie";
import cassieltexteditor from "@/views/edit/Component/cassieltexteditor";
import cassielMultipleSelectfield from "@/views/edit/Component/cassielMultipleSelectfield";
import cassielnumberfield from "@/views/edit/Component/cassielnumberfield";
import cassieldropzone from "@/views/edit/Component/cassielDropzone";
import cassieldropplace from "@/views/edit/Component/cassielDropplace";
import cassielDropplaceMenu from "@/views/edit/Component/cassielDropplaceMenu";
import cassiellistfields from "@/views/edit/Component/cassielListFields";
import cassielcheckbox from "@/views/edit/Component/cassielcheckbox";
import cassielscheduledayscheckbox from "@/views/edit/Component/cassielscheduledayscheckbox";
import Cassielhiddenfield from "@/views/edit/Component/cassielhiddenfield";
import Cassieldatatable from "@/views/edit/Component/cassielDatatable";
import cassieltextfieldemoji from "@/views/edit/Component/cassieltextfieldemoji";
import cassielcolorpicker from "@/views/edit/Component/cassielcolorpicker";
import cassieldisplayfield from "@/views/edit/Component/cassieldisplayfield";
import cassieluploadpdf from "@/views/edit/Component/cassieluploadpdf";
import cassielDropDown from "@/views/edit/Component/cassielDropDown";
import cassielexportbutton from "@/views/edit/Component/cassielexportbutton";
import draggable from "vuedraggable";

export default {
    name: "cassielFieldsRender",
    mixins: [],
    props: {
        index: {
            type: Number,
        },
        parentIndex: {
            type: Number,
        },
        field: {
            type: [Array, Object],
        },
        formConfiguration: {
            type: [Array, Object],
        },
        cassielid: {
            type: String,
        },
        widgetName: {
            type: String,
            required: true,
        },
        entityName: {
            type: String,
            required: true,
        },
    },
    components: {
        cassielDropDown,
        Cassieldatatable,
        Cassielhiddenfield,
        cassieltextfield,
        cassieldatetime,
        cassielcropie,
        cassieltexteditor,
        cassielMultipleSelectfield,
        cassielnumberfield,
        cassieldropzone,
        cassieldropplace,
        cassielDropplaceMenu,
        cassielcheckbox,
        cassieltextarea,
        cassielsnippet,
        cassielseo,
        cassieltextfieldemoji,
        cassielcolorpicker,
        cassieluploadpdf,
        cassieltime,
        cassielscheduledayscheckbox,
        cassiellistfields,
        cassieldisplayfield,
        cassielexportbutton,
        draggable,
    },
    data() {
        return {
            debug: process.env.VUE_APP_FORM_DEBUG,
        };
    },
    created() {
    },
    methods: {
        addRow: function (e, field, index) {
            e.preventDefault();
            this.$emit("addrow", e, field, index);
        },

        removeRow: function (e, fieldData, index) {
            e.preventDefault();
            if (confirm("Are you sure you want to remove this entry?")) {
                this.$emit("removerow", e, fieldData, index);
            }
        },
        renameField() {
            this.$emit('renamefield');
        },
        valueChanged: function (value, fieldName) {
            this.$emit("valueChanged", value, fieldName);
        },
        handleParentDropDown: function (e) {
            let parent = e.target.parentElement.lastChild;
            if(parent.classList.contains('active')){
                parent.classList.remove('active');
                e.target.classList.remove("active");
            }
            else {
                parent.classList.add('active');
                e.target.classList.add("active");
            }
        },
        handleChildDropDown: function (e) {
            let parent = e.target.parentElement;
            if(parent.classList.contains('active')){
                parent.classList.remove('active');
                e.target.classList.remove("active");
            }
            else {
                parent.classList.add('active');
                e.target.classList.add("active");
            }
        },
    },
};
</script>
