<template>
  <div class="form-group">
    <label :style="field.styles" class="text-monospace text-bold">
      {{ field.label }}
    </label>
    <div>
      {{ field.value }}
    </div>
  </div>
</template>
<style>

</style>
<script>
export default {
  name: "cassieldisplayfield",
  mixins: [],
  props: {
    field: {
      type: Object
    },
    index: {
      type: Number
    }
  }
};
</script>