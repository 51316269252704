<template v-solt>
    <div style="display: flow-root">
        <div>
            <input type="text" id="sectionName" style="margin-right: 10px"/>
            <button class="btn cursor-pointer" type="button" @click="addSection" style="background-color: #586bb2; border-radius: 5px; ">Add section</button>
        </div>
    <div class="renderedTemplate ">
      <div v-for="(section, sectionindex) in field.sections" :key="sectionindex">
        <div class="home-page-section">
          <div class="text-monospace text-bold text-capitalize home-page-section-title">
            {{ section.label }}
          </div>
          <div v-bind:id="section.name" class="cassiel-dropplace-field">
            <div data-limit="1" class="list-group-item" style="">
              <draggable class="list-group" :list="field.data[section.name]" v-model="field.data[section.name]" draggable=".item"
                         :group="'section'" @change="updateForm($event, section.name)" @start="drag=true"
                         @end="drag=false">
                <div v-bind:id="section.name+'_'+index" v-for="(record, index) in field.data[section.name]" :key="index"
                     class="cassiel-dropplace onHover item"
                     style="margin-top: 10px; border: 2px solid; padding: 20px; border-radius: 10px;">
                  <div v-for="(template, templateIndex) in templates" :key="templateIndex">
                    <div v-if="template.fieldType == record.fieldType">
                                            <span>
                                                {{ template.labels }}
                                            </span>
                      <div @click="removeDataOnCLick(section.name, index)"
                           class="fa fa-remove cassiel-droped-record-remove"></div>
                      <div v-if="record.fieldType == 'CassielStaticText'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue" value="CassielStaticText"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Text</label>
                            <froala :tag="'textarea'"
                                    :config="configField"
                                    :change="updateFormValue"
                                    v-model="record.text"
                                    name="text"
                                    placeholder="text"
                                    class="form-control froala-editor"></froala>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielTextField'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielTextField"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                         class="form-control"/></div>
                          <div><label>required</label><select name="required" @change="updateFormValue"
                                                              class="form-control">
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </select></div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielEmailField'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielEmailField"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                          class="form-control"/></div>
                          <div><label>required</label><select name="required" @change="updateFormValue"
                                                              class="form-control">
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </select></div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielLinkField'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielLinkField"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Url</label><input name="url" @change="updateFormValue" placeholder="url" class="form-control"/></div>
                          <div><label>Icon</label><input name="icon" @change="updateFormValue" placeholder="fa" class="form-control"/></div>
                          <div><label>className</label><input name="className" @change="updateFormValue" placeholder="Style" class="form-control"/></div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielVideoUploader'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielVideoUploader"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                          class="form-control"/></div>
                          <div><label>required</label><select name="required" @change="updateFormValue"
                                                              class="form-control">
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </select></div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielTextArea'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielTextArea"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                          class="form-control"/></div>
                          <div><label>required</label><select name="required" @change="updateFormValue"
                                                              class="form-control">
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </select></div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielDropDown'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielDropDown"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                          class="form-control"/></div>
                          <div><label>Values</label><input name="values"  @change="updateFormValue" placeholder="example 1, example 2, ..." class="form-control"/></div>
                        </div>
                        <div><label>required</label><select name="required" @change="updateFormValue"
                                                            class="form-control">
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </select></div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielCheckBox'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielCheckBox"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                          class="form-control"/></div>
                          <div><label>Values</label><input name="values"  @change="updateFormValue" placeholder="example 1" class="form-control"/></div>
                        </div>
                        <div><label>required</label><select name="required" @change="updateFormValue"
                                                            class="form-control">
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </select></div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielDateTimeField'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielDateTimeField"
                                      placeholder="Label"/></div>
                          <div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name"
                                                         class="form-control"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                          class="form-control"/></div>
                          <div><label>required</label><select name="required" @change="updateFormValue"
                                                              class="form-control">
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </select></div>
                        </div>
                      </div>

                      <div v-else-if="record.fieldType == 'CassielEmailSettingsField'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielEmailSettingsField"
                                      placeholder="Label"/></div>
                          <div><label>Email</label><input name="email" @change="updateFormValue" placeholder="Email"
                                                         class="form-control"/></div>
                          <div><label>CC</label><input name="cc" @change="updateFormValue" placeholder="CC"
                                                          class="form-control"/></div>
                          <div><label>Subject</label><input name="subject" @change="updateFormValue" placeholder="Subject"
                                                          class="form-control"/></div>
                          <div><label>Twig File Path</label><input name="twigfilepath" @change="updateFormValue" placeholder="Twig file path"
                                                          class="form-control"/></div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType === 'CassielStaticImage'">
                        <div class="form-group">
                          <div>
                            <input  type="hidden" name="fieldType"  v-model="record.fieldType"  placeholder="Label" />
                          </div>
                          <div>
                            <label>Name</label>
                            <input  name="name" v-model="record.name"  placeholder="Name"  class="form-control"  />
                          </div>
                          <div>
                            <label>Image</label>
                            <cassielcropie
                                :field="croppieField"
                                :index="index"
                                :parentIndex="sectionindex"
                                :name="'pagebuilder'+sectionindex+'_'+index"
                                custom="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else-if="record.fieldType == 'CassielButon'">
                        <div class="form-group ">
                          <div><input type="hidden" name="fieldType" @change="updateFormValue"
                                      value="CassielButon"
                                      placeholder="Label"/></div>
                          <div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label"
                                                          class="form-control"/></div>
                          <div><label>icon</label><input name="name" @change="updateFormValue" placeholder="fa icon"
                                                         class="form-control"/></div>
                          <div><label>Background Color</label><input name="background-color" type="color" @change="updateFormValue" placeholder="Label" class="form-control"/></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>

        </div>
      </div>
    </div>

      <div class="fieldstemplates" ref="cassielLibrary" id="cassiel-library" style="  right: 1.5%">
      <div> list of fields</div>
      <div class="tab-content" id="pills-tabContent">
        <draggable class="list-group" :list="templates" draggable=".item" v-model="templates"
                   :group="{ name: 'section', pull: 'clone', put: false }">
          <div class="list-group-item item onHover" v-for="(template,index) in templates" :key="index"
               data-limit="1">
            <div v-if="template">
                 <span>
                    {{ template.labels }}
                 </span>
              <div @click="removeDataOnCLick(section.name, index)"
                   class="fa fa-remove cassiel-droped-record-remove"></div>
              <div v-html="template.requiredData" class="hiddenInDrag">
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
      <input :value="formDataString" type="hidden" name="formData">
      <input :value="JSON.stringify(field.sections)" type="hidden" name="sectionsObject">
  </div>


</template>

<script>
import draggable from 'vuedraggable'
import cassielcropie from "@/views/edit/Component/cassielcropie";

export default {
    name: "cassiellistfields",
    mixins: [],
    props: {
        parentIndex: {
            type: Number
        },
        index: {
            type: Number
        },
        formConfiguration: {
            type: [Array, Object]
        },
        field: {
            type: [Array, Object]
        }
    },
    data: function (vm) {
        return {
            entityData: [],
            entityTypes: [],
            formData: {},
            formDataString: '',
            templates: [],
            is_data_fetched: false,
            pageBuilder: [],
            resultObj: new Object(),
            direction: '',
            key: '',
            croppieField: {
                label: '',
                name: '',
                value: '',
                widgetName: 'pagebuilder',
                custom: true,
                filter: 'default',
                width: 640,
                height: 360,
            },
            configField: {
                heightMin: 100,
                events: {
                    initialized: function () {
                    },
                    contentChanged() {
                        vm.updateFormValue(this.html.get()) // That works ok
                    },
                }
            }
        }
    },
    components: {
        draggable,
        cassielcropie
    },
    mounted: function () {
        this.direction = process.env.VUE_APP_FROALA_DIRECTION;
        this.key = process.env.VUE_APP_FROALA_KEY;
        this.configField.key = this.key;
        this.configField.direction = this.direction;
        this.templates = [
            {
                'labels': 'Text Field',
                'fieldType': 'CassielTextField',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType" value="CassielTextField" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name"  v-on:change="updateFormValue"placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label" class="form-control" value=""/></div>' +
                    '<div><label>required</label><select name="required" @change="updateFormValue" class="form-control"><option value="true">true</option><option value="false">false</option> </select> </div>' +
                    '</div>' +
                    '</div>'
            },
            {
                'labels': 'Email',
                'fieldType': 'CassielEmailField',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType"  value="CassielEmailField" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label" class="form-control"/></div>' +
                    '</div>'
            },
            {
                'labels': 'Link',
                'fieldType': 'CassielLinkField',
                'requiredData': '<div class="form-group "> ' +
                    '<div><input type="hidden" name="fieldType" value="CassielLinkField" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name" class="form-control"/></div>' +
                    // '<div><label>Url</label><input name="url" @change="updateFormValue" placeholder="url" class="form-control"/></div>' +
                    // '<div><label>Icon</label><input name="icon" @change="updateFormValue" placeholder="icon" class="form-control"/></div>' +
                    // '<div><label>ClassName</label><input name="className" @change="updateFormValue" placeholder="style" class="form-control"/></div>' +
                    '</div>'
            },
            {
                'labels': 'Video',
                'fieldType': 'CassielVideoUploader',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType" value="CassielVideoUploader" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label" class="form-control"/></div>' +
                    '</div>'
            },
            {
                'labels': 'Text Area',
                'fieldType': 'CassielTextArea',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType" value="CassielTextArea" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name"  @change="updateFormValue"placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label" class="form-control"/></div>' +
                    '</div>'
            },
            {
                'labels': 'Drop Down',
                'fieldType': 'CassielDropDown',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType" value="CassielDropDown" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label" class="form-control"/></div>' +
                    '<div><label>Values</label><input name="values"  @change="updateFormValue" placeholder="example 1, example 2, ..." class="form-control"/></div>' +
                    '</div>'
            },
            {
                'labels': 'Check Box',
                'fieldType': 'CassielCheckBox',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType" value="CassielCheckBox" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label" class="form-control"/></div>' +
                    '<div><label>Value</label><input name="values" @change="updateFormValue" placeholder="example 1" class="form-control"/></div>' +
                    '</div>'
            },
            {
                'labels': 'Date',
                'fieldType': 'CassielDateTimeField',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType" value="CassielDateTimeField" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name" @change="updateFormValue" placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Label</label><input name="label" @change="updateFormValue" placeholder="Label" class="form-control"/></div>' +
                    '</div>'
            },
            {
                'labels': 'Static Field',
                'fieldType': 'CassielStaticText',
                'requiredData': '<div class="form-group" > ' +
                    '<div><input type="hidden" name="fieldType" value="CassielStaticText" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input name="name" v-on:change="updateFormValue" placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Text</label><froala :tag="textarea" @change="updateFormValue" id="edit" :config="config" name="text" placeholder="text" class="form-control froala-editor"> </froala></div>' +
                    '</div>'
            },
            {
                labels: "Static Image",
                fieldType: "CassielStaticImage",
                requiredData:
                    '<div class="form-group"> ' +
                    '<div><input type="hidden" value="CassielStaticImage" placeholder="Label"/></div>' +
                    '<div><label>Name</label><input placeholder="Name" class="form-control"/></div>' +
                    '<div><label>Image</label><input type="file" class="form-control"/></div>' +
                    "</div>",
            },
            {
                labels: "Button",
                fieldType: "CassielButon",
                requiredData:
                    '<div class="form-group"> ' +
                    '<div><input type="hidden" value="CassielStaticImage" placeholder="Label"/></div>' +
                    '<div><label>Label</label><input placeholder="Label" class="form-control"/></div>' +
                    '<div><label>Icon</label><input placeholder="Icon" class="form-control"/></div>' +
                    '<div><label>Background Color</label><input type="color" placeholder="Background Color" class="form-control"/></div>' +
                    "</div>",
            },
          {
                labels: "Email Settings",
                fieldType: "CassielEmailSettingsField",
                requiredData:
                    '<div class="form-group"> ' +
                    '<div><input type="hidden" value="CassielEmailSettingsField" placeholder="Label"/></div>' +
                    '<div><label>Email</label><input placeholder="Email" class="form-control"/></div>' +
                    '<div><label>CC</label><input type="text" class="form-control"/></div>' +
                    '<div><label>Subject</label><input type="text" class="form-control"/></div>' +
                    '<div><label>Twig File Path</label><input type="text" class="form-control"/></div>' +
                    "</div>",
            },
        ];
        let $ = require("jquery");
        if ($('#cassielId').val() !== '') {
            this.$nextTick(function () {
                for (var i = 0; i < this.field.sections.length; i++) {
                    if (this.field.data[this.field.sections[i].name] != undefined) {
                        for (var c = 0; c < this.field.data[this.field.sections[i].name].length; c++) {
                            for (var item in this.field.data[this.field.sections[i].name][c]) {
                                $('#' + this.field.sections[i].name + '_' + c + ' input[name="' + item + '"]').val(this.field.data[this.field.sections[i].name][c][item]);
                                $('#' + this.field.sections[i].name + '_' + c + ' textarea[name="' + item + '"]').val(this.field.data[this.field.sections[i].name][c][item]);
                                $('#' + this.field.sections[i].name + '_' + c + ' select[name="' + item + '"]').val(this.field.data[this.field.sections[i].name][c][item]);
                            }
                        }
                    }
                }
                this.updateForm()
            });
            this.$nextTick(function () {
                // new FroalaEditor('textarea.froala-editor',{
                //   key: '{{ forala_api_key_v3 }}'
                // })
            })
        }
    },
    methods: {
        removeDataOnCLick: function (section, index) {
            this.$delete(this.field.data[section], index, '');
            this.updateFormArray();
        },
        updateForm: function (evt, sectionName) {
            if (evt != undefined && (sectionName != undefined && sectionName != '')) {
                if (evt.added != undefined) {
                    this.$set(this.field.data[sectionName], this.field.data[sectionName].length, evt.added.element);
                    this.$delete(this.field.data[sectionName], Number(evt.added.newIndex));
                }
            }
            this.updateFormArray();
        },
        addSection: function () {
            let $ = require("jquery");
            var sectionName = $("#sectionName").val();
            if (sectionName != '') {
                this.$nextTick(function () {
                    this.field.sections.push({'label': sectionName, 'name': sectionName});
                    this.field.data[sectionName] = [];
                    $("#sectionName").val('')
                })
            }
        },
        updateFormArray: function(){
            this.$nextTick(function () {
                let $ = require("jquery");

                this.field.sections.map(section => {
                    if (this.field.data[section.name] !== undefined) {
                        this.field.data[section.name].map((dataSection, dataSectionIndex) => {
                            Object.keys(dataSection).forEach(function (key) {
                                let id = section.name + '_' + dataSectionIndex;
                                $('#' + id + ' input[name="' + key + '"]').val(dataSection[key]);
                                $('#' + id + ' select[name="' + key + '"]').val(dataSection[key]);
                            });
                        })
                    }
                })

                this.updateFormValue();
            });
        },
        updateFormValue: function () {
            let $ = require("jquery");
            let $this = this;

            this.field.sections.map(section => {
                $this.formData[section.name] = [];
                $('#' + section.name + ' .onHover').each(function () {
                    let formObject = new Object();
                    $(this).find('input').each(function () {
                        formObject[$(this).attr("name")] = $(this).val();
                    });
                    $(this).find('textarea').each(function () {
                        formObject[$(this).attr("name")] = $(this).val();
                    });
                    $(this).find('select').each(function () {
                        formObject[$(this).attr("name")] = $(this).val();
                    });
                    $this.formData[section.name].push(formObject);
                });

                this.formDataString = JSON.stringify($this.formData, true)
            });

        }
    },
}
;
</script>

<style>
label {
  margin-top: 0.5rem;
}
.cassiel-dropplace{
  display: block!important;
}
.cassiel-dropplace div span {
  color: #586bb2;
  font-size: 18px;
  font-weight: bold;
}

.renderedTemplate {
  width: 55%;
  float: left;
  /*border: solid 1px black;*/
}

.tinted {
  background-color: lightgoldenrodyellow;
}

.fieldstemplates {
  float: right;
  width: 35%;
  border: 1px solid black;
  background-color: #e9ecef;
}

.nav-item {
  margin-right: 5px;
}

.nav-pills .nav-link, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #212121;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  border-bottom: 3px solid teal;
}

.onHover {
  cursor: pointer;
}

.fieldstemplates div {
  font-size: 24px;
  margin-bottom: 10px;
}

.fieldstemplates .grid {
  display: flex;
  width: 100%;
}

.fieldstemplates .grid input {
  margin: auto;
  margin-right: 10px;
}

.fieldstemplates div div {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}
#cassiel-library{
  position: sticky;
  top: 82px;
  max-height: 100%;
  overflow-y: auto;
}
#pills-tabContent{
  max-height: 500px;
  overflow-y: scroll;
}
#cassiel-library div div div input {
  display: none;
}

#cassiel-library div div div label {
  display: none;
}

#cassiel-library div div div h1 {
  font-size: 24px;
  margin: 0px;
}

.list-group-item.tinted {
  background-color: white;
  border: 1px dotted teal;
  margin-bottom: 10px;
  padding: 14px;
  padding-bottom: 5px;
  direction: ltr;
}

span {
  cursor: pointer;
}

.list-group-item div div.hiddenInDrag {
  display: none;
}

.cassiel-dropplace-field div div div.hiddenInDrag {
  display: block !important;
}

.list-group-item-close-btn {
  position: absolute;
  right: 16px;
  font-size: 33px;
  color: red;
}

#cassiel-library div div .list-group-item-close-btn {
  display: none;
}

div#cassiel-library {
  padding: 10px;
}

.home-page-section {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  margin: 5px;
  /*border: 1px teal double;*/
}

.home-page-section-title {
  font-size: 24px;
}

.home-page-section-content {
  text-align: center;
}

.cassiel-dropplace-field {
  /*border: 1px dashed gray;*/
  min-height: 50px;
  margin: 5px;
  padding: 5px 0;
}

div#pills-tabContent div div div.cassiel-droped-record-remove {
  display: none;
}

.cassiel-droped-record-remove {
  float: right;
  /*margin-top: -15px;*/
  /*margin-right: 10px;*/
  color: red;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.cassiel-droped-record {
  cursor: pointer;
}

.home-page-section-input {
  outline: none;
  background: transparent;
  border: none;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
  display: block;
}

.state-2 {
  color: #f0ad4e;
}

.state-1 {
  color: #00a550;
}

.state-0 {
  color: #1e90ff;
}

.square-green {
  height: 10px;
  width: 10px;
  background-color: #00a550;
}

.square-yellow {
  height: 10px;
  width: 10px;
  background-color: #f0ad4e;
}

.square-blue {
  height: 10px;
  width: 10px;
  background-color: #1e90ff;
}

.hint {
  margin-bottom: 0px;
  font-size: 16px;
  margin-block-start: 0;
  margin-top: -5px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>