<template>
  <div class="form-group">
    <label
      class="text-monospace text-bold text-capitalize"
      :style="field.styles"
      >{{ field.label }}
      <span v-if="field.required" class="text-danger">*</span>
    </label>
    <input
      :list="field.name"
      :readonly="field.disable === 1"
      :type="field.type || 'text'"
      v-model="field.value"
      @input="getvalue"
      :name="field.name"
      class="form-control"
      :placeholder="field.placeholder"
    />
    <datalist :id="field.name">
      <option
        v-for="(data, dataindex) in field.values"
        :key="dataindex"
        :value="data.value"
      >
        {{ data.value }}
      </option>
    </datalist>
    <span
      :id="field.name + '_error'"
      class="b-toast-danger alert alert-danger cassiel-error"
      :style="{ display: 'none' }"
    />
  </div>
</template>
<style></style>
<script>
export default {
  name: "cassieltextfield",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      values: [],
    };
  },
  methods: {
    getvalue: function () {
      if (this.field.datalist === "1") {
        const token = this.$store.getters["auth/getToken"];
        let headers = {};
        if (token) {
          headers["X-AUTH-TOKEN"] = token;
        }
        let bodyFormData = new FormData();
        bodyFormData.append("q", this.field.value);
        this.$http({
          url:
            process.env.VUE_APP_CMS_BACKEND_BASE_URL +
            "" +
            this.field.datalistapi,
          method: "POST",
          data: bodyFormData,
          headers: headers,
        })
          .then((response) => {
            this.values = response.data.filter((v) => v.value.trim() !== "");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>
