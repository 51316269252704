<template>
  <div style="display: flex; justify-content: space-between">
    <button type="submit" disabled style="display: none" aria-hidden="true"></button>

    <div class="renderedTemplate">
      <div v-for="(section, sectionindex) in field.value" :key="sectionindex">
        <div
          class="home-page-section"
          v-if="
            field.data[section.name] && Array.isArray(field.data[section.name])
          "
        >
          <div class="text-monospace home-page-section-title">
            {{ section.label }}
          </div>
          <draggable
            class="list-group"
            :list="field.data[section.name]"
            :group="section.data.entity"
            :data-section="section.name"
            @change="sorting($event, section.name)"
          >
            <div
              class="home-page-section-content"
              v-for="index in section.data.count"
              :key="index"
            >
              <div
                :data-type="section.data.entity"
                :data-sectionname="section.name"
                :data-index="index - 1"
                :id="section.name + '_' + index"
                class="cassiel-dropplace"
              >
                <span class="section-label">
                  {{ section.data.entity + "  " + index }}
                </span>

                <div
                  v-if="
                    field.data[section.name] &&
                    field.data[section.name] !== undefined &&
                    field.data[section.name][index - 1] !== undefined &&
                    field.data[section.name][index - 1] &&
                    field.data[section.name][index - 1].title !== ''
                  "
                  :data-type="section.data.entity"
                  :data-sectionname="section.name"
                  :data-index="index - 1"
                  class="cassiel-droped-record"
                >
                  <span class="home-page-section-input">
                    {{ field.data[section.name][index - 1].title }}
                  </span>

                  <input
                    type="hidden"
                    class="home-page-input"
                    :data-url="field.data[section.name][index - 1].url"
                    :data-title="field.data[section.name][index - 1].title"
                    :value="field.data[section.name][index - 1].url"
                    :name="section.name + 'Url[]'"
                  />
                  <input
                    type="hidden"
                    class="home-page-section-input"
                    :data-url="field.data[section.name][index - 1].url"
                    :data-title="field.data[section.name][index - 1].title"
                    :value="field.data[section.name][index - 1].title"
                    :name="section.name + 'Title[]'"
                  />
                </div>

                <div v-else class="drag-article-container">
                  Drag an article here

                  <input
                    type="hidden"
                    class="home-page-input"
                    data-url=""
                    data-title=""
                    value=""
                    :name="section.name + 'Url[]'"
                  />
                  <input
                    type="hidden"
                    class="home-page-section-input"
                    data-url=""
                    data-title=""
                    value=""
                    :name="section.name + 'Title[]'"
                  />
                </div>

                <div
                  v-if="
                    field.data[section.name] &&
                    field.data[section.name] !== undefined &&
                    field.data[section.name][index - 1] !== undefined &&
                    field.data[section.name][index - 1]
                  "
                  @click="removeDataOnCLick(section.name, index - 1)"
                  class="cassiel-droped-record-removebtn"
                >
                  Delete
                </div>
                <div v-else class="cassiel-droped-record-removebtn disabled">
                  Delete
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>

    <div style="width: 40%">
      <div class="templates" ref="cassielLibrary" id="cassiel-library" style="">
        <div class="text-monospace sticky-header">
          List of Articles
          <span>*can't insert pending element</span>
        </div>

        <div class="search-container">
          <input
            id="searchInput"
            type="text"
            name="searchInput"
            ref="searchInput"
            class="form-control"
            placeholder="Search"
            value=""
            v-on:keyup.enter="filterData"
          />
          <i class="mdi mdi-magnify" @click="filterData"></i>
        </div>
        <!-- <div style="display: flex; margin-bottom: 0">
          <div class="square-blue"></div>
          <p class="hint">Pending</p>
          <div class="square-green"></div>
          <p class="hint">Published</p>
          <div class="square-yellow"></div>
          <p class="hint">Modified</p>
        </div> -->

        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li
            v-for="(entityType, entityTypeindex) in entityTypesName"
            class="nav-item"
            :key="entityTypeindex"
          >
            <a
              class="nav-link"
              :class="{ active: entityTypeindex === 0 }"
              :id="'pills-' + entityType + '-tab'"
              data-toggle="pill"
              :href="'#' + entityType + 'dragDiv'"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              @click.prevent="activeTab(entityType)"
            >
              {{ entityType }}
            </a>
          </li>
        </ul>
        <p ref="status"></p>
        <div class="tab-content" id="pills-tabContent">
          <div
            v-for="(entityType, entityTypeindex) in entityTypesName"
            :key="entityTypeindex"
            class="tab-pane fade"
            :class="{ 'show active': entityTypeindex === 0, 'show active' : setActiveTab === entityType }"
            :aria-labelledby="'pills-'+entityType+'-tab'"
            v-bind:id="entityType + 'dragDiv'"
          >
            <draggable
              class="list-group"
              :list="entityData[entityType]"
              :group="{ name: entityType, pull: 'clone', put: false }"
              draggable=".item"
            >
              <div
                :class="
                  entityData[entityType][index].state === '1' ||
                  entityData[entityType][index].state === '2'
                    ? 'list-group-item tinted item'
                    : 'list-group-item tinted'
                "
                :data-entitytype="entityType"
                :data-index="index"
                v-for="(entity, index) in entityData[entityType]"
                :key="index"
                data-limit="1"
              >
                <span
                  class="list-state s-1"
                  v-if="entityData[entityType][index].state === '1'"
                  >published</span
                >
                <span
                  class="list-state s-2"
                  v-else-if="entityData[entityType][index].state === '2'"
                  >modified</span
                >
                <span class="list-state s-0" v-else>pending</span>

                <span
                  class="list-article-title"
                  :data-state="entity.state"
                  :data-type="entity.entitytype"
                  :data-url="entity.url"
                  :data-title="entity.title"
                >
                  {{ entity.title }}
                </span>
                <input
                  :data-state="entity.state"
                  :data-type="entity.entitytype"
                  :data-url="entity.url"
                  :data-title="entity.title"
                  type="hidden"
                  :value="entity.url"
                  disabled
                />
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "cassieldropplace",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data: function () {
      return {
          entityTypes: [],
          entityTypesName: [],
          entityData: [],
          setActiveTab: ''
      };
  },
  components: {
    draggable,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted: function () {
    for (let j = 0; j < this.field.value.length; j++) {
      if (!this.entityTypesName.includes(this.field.value[j].data.entity)) {
        this.entityTypes.push(this.field.value[j].data);
        this.entityTypesName.push(this.field.value[j].data.entity);
      }
    }

    for (let section in this.field.data) {
      let sectionList = [];
      for (let e in this.field.data[section]) {
        sectionList.push(this.field.data[section][e]);
      }
      this.field.data[section] = sectionList;
      this.$forceUpdate();
    }
    this.filterData();

  },
  methods: {
    handleScroll() {
      if (window.scrollY >= 130) {
        if (this.$refs && this.$refs.cassielLibrary) {
          this.$refs.cassielLibrary.setAttribute("style", " top:80px");
        }
      } else {
        if (this.$refs && this.$refs.cassielLibrary) {
          this.$refs.cassielLibrary.setAttribute(
            "style",
            " top:" + (240 - window.scrollY) + "px"
          );
        }
      }
    },
    sorting: function (evt, section) {
      if (evt.added !== undefined) {
        let includes = 0;
        let inputs = document.getElementsByClassName("home-page-input");
        for (let i = 0; i < inputs.length; i++) {
          if (
            inputs[i].value === this.field.data[section][evt.added.newIndex].url
          ) {
            includes++;
            if (includes === 2) {
              break;
            }
          }
        }
        if (includes === 2) {
          this.$delete(this.field.data[section], Number(evt.added.newIndex));
          this.$swal({
            title: "Exists",
            text: "This record already exists in homepage",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#008080",
            confirmButtonText: "Ok",
          });
        } else {
          this.$delete(
            this.field.data[section],
            Number(evt.added.newIndex) + 1
          );
        }
      }
      if (evt.removed !== undefined) {
        for (
          let c = this.field.data[section].length;
          c > evt.removed.oldIndex;
          c--
        ) {
          this.$set(
            this.field.data[section],
            c,
            this.field.data[section][c - 1]
          );
        }
        this.$set(this.field.data[section], evt.removed.oldIndex, "");
      }
    },
    removeDataOnCLick: function (section, index) {
      this.$set(this.field.data[section], index, "");
    },
    filterData: function (e) {
      if (e !== undefined) {
        e.preventDefault();
      }
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      const baseListDataApi = this.$apiList.baseListDataApi;
        for (let j = 0; j < this.entityTypes.length; j++) {
        let bodyFormData = new FormData();
        bodyFormData.append("length", 10);
        bodyFormData.append("draw", 1);
        bodyFormData.append("start", 0);
        bodyFormData.append("search", this.$refs.searchInput.value);

        let api = baseListDataApi + "/" + this.entityTypes[j].widget + "/" + this.entityTypes[j].entity;
        if(this.entityTypes[j].url !== undefined) {
          api = this.entityTypes[j].url;
        }

        this.$http({
          url: api,
          method: "POST",
          data: bodyFormData,
          headers: headers,
        })
          .then((response) => {
            this.$set(this.entityData, response.data.entity, []);
              if(response.data.data !== undefined && response.data.entity !== undefined) {
                  for (let i = 0; i < response.data.data.length; i++) {
                      if (!this.entityData[response.data.entity][i]) {
                          this.$set(this.entityData[response.data.entity], i, []);
                      }
                      let length = response.data.data[i].length;
                      let row = {
                          entitytype: response.data.entity,
                          title: response.data.data[i][1],
                          url: response.data.data[i][length - 1].replace("/preview", ""),
                          state: response.data.data[i][3].toString(),
                      };
                      this.$set(this.entityData[response.data.entity], i, row);
                  }
              }
          })
          .catch((error) => {
            this.$refs.status.innerHTML = "An Error Occured : " + error;
            this.$refs.status.setAttribute("class", "text-danger");
          });
      }
    },
    activeTab: function (entityType) {
        this.setActiveTab = entityType;
      const el = document.getElementsByClassName("nav-link");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove("active");
      }
      document
        .getElementById("pills-" + entityType + "-tab")
        .classList.add("active");
    },
  },
};
</script>

<style>
.renderedTemplate {
  width: 58%;
  float: left;
}

.list-group {
  /* padding: 0 22px; */
}
.list-group .home-page-section-content {
  background: #fff;
}
.list-group .home-page-section-content:nth-child(even) {
  background: #eff0f9;
}
.cassiel-dropplace {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tinted {
  background-color: lightgoldenrodyellow;
}

.templates {
  background-color: #fff;
  position: sticky;
}
.sticky-header {
  background: #c1c6e5;
  color: #586bb2;
  font-size: 25px;
  font-weight: 500;
  padding: 16px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.sticky-header span {
  font-size: 12px;
  color: #586bb2;
}
.nav-item {
  margin-right: 5px;
  margin-bottom: 5px;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #212121;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  /* border-bottom: 3px solid teal; */
}

.onHover {
  cursor: pointer;
}

/* .templates div { */
/* font-size: 24px; */
/* margin-bottom: 10px; */
/* } */
.search-container {
  background: #eff0f9;
  padding: 11px 22px;
  position: relative;
}
.search-container input {
  font-size: 18px !important;
  font-weight: 300 !important;
  max-width: 100%;
  width: 100%;
  padding-left: 45px;
}
.search-container input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c1c6e5;
}
.search-container input::-moz-placeholder {
  /* Firefox 19+ */
  color: #c1c6e5;
}
.search-container input:-ms-input-placeholder {
  /* IE 10+ */
  color: #c1c6e5;
}
.search-container input:-moz-placeholder {
  /* Firefox 18- */
  color: #c1c6e5;
}
.search-container i {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 28px;
  color: #586bb2;
  transform: translateY(-50%);
  cursor: pointer;
}

.templates div div {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: right;
}
.templates .list-group {
  overflow-x: auto;
  max-height: 60vh;
  padding: 0 22px;
}
.list-group-item.tinted .list-article-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: right;
  direction: rtl;
  padding-left: 107px;
}

#cassiel-library div div div input {
  display: none;
}

#cassiel-library div div div label {
  display: none;
}

#cassiel-library div div div h1 {
  font-size: 24px;
  margin: 0px;
}

.list-group-item.tinted {
  position: relative;
  background: #fff;
  margin-bottom: 12px;
  padding: 14px;
  direction: ltr;
}
.section-label {
  font-size: 14px;
  color: #586bb2;
  text-transform: capitalize;
  font-weight: 400;
}

span {
  cursor: pointer;
}

.list-group-item-close-btn {
  position: absolute;
  right: 16px;
  font-size: 33px;
  color: red;
}

#cassiel-library div div .list-group-item-close-btn {
  display: none;
}

.home-page-section {
  margin-bottom: 26px;
}

.home-page-section-title {
  font-size: 25px !important;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  background: #586bb2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 16px 19px;
}

.home-page-section-content {
  text-align: center;
}

.cassiel-dropplace {
  min-height: 65px;
  padding: 23px 14px;
}
.drag-article-container {
  color: #c1c6e5;
  border: 1px solid #c1c6e5;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
}
.cassiel-droped-record-removebtn {
  color: #e41c42;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
}
.cassiel-droped-record-removebtn.disabled {
  color: #b3b8c9;
  cursor: default;
}
.cassiel-droped-record {
  cursor: pointer;
}

.home-page-section-input {
  outline: none;
  background: transparent;
  border: none;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
  display: block;
}

.state-2 {
  color: #f0ad4e;
}

.state-1 {
  color: #00a550;
}

.state-0 {
  color: #1e90ff;
}

.square-green {
  height: 10px;
  width: 10px;
  background-color: #00a550;
}

.square-yellow {
  height: 10px;
  width: 10px;
  background-color: #f0ad4e;
}

.square-blue {
  height: 10px;
  width: 10px;
  background-color: #1e90ff;
}

.hint {
  margin-bottom: 0px;
  font-size: 16px;
  margin-block-start: 0;
  margin-top: -5px;
  margin-right: 5px;
  margin-left: 5px;
}

.list-state {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 113px;
}
.list-state.s-1 {
  color: #39b551;
  background: #cef2d5;
}
.list-state.s-2 {
  background: #fef5df;
  color: #ffb300;
}
.list-state.s-0 {
  background: #f5ebed;
  color: #e41c42;
}
</style>
