<template>
  <vs-row :style="{ margin: '0px' }">
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
      >
        <div class="row">
          <div class="col-12">
            <div v-if="loading">
              <h3>Loading Data ...</h3>
              <vs-progress indeterminate color="dark">primary</vs-progress>
            </div>
            <div v-else>
              <vs-table
                :id="grid.id"
                :data="filteredData"
                :sst="true"
                @search="handleSearch"
                @change-page="handleChangePage"
                @sort="handleSort"
                :total="totalItems"
                :max-items="10"
                search
                stripe
                noDataText="No Available Data, click to Create new ones in the top right corner."
                class="text-nowrap"
              >
                <template slot="header"> </template>
                <template slot="thead">
                  <vs-th
                    class="text-left w-30"
                    v-for="field in field.data['datatablecolumns']"
                    :key="field.id"
                    v-bind:sort-key="field.name"
                  >
                    {{ field.label }} <i class="mdi mdi-sort"></i>
                  </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td
                      :key="field"
                      class="text-left w-30"
                      v-for="(field, indextd) in data[indextr]"
                    >
                      <p
                        v-if="indextd !== data[indextr].length - 1"
                        v-html="field"
                      ></p>
                      <p v-if="indextd === data[indextr].length - 1">
                        <button
                          v-bind:href="field"
                          target="_blank"
                          class="btn-add"
                          @click="addRow($event, indextr, field)"
                          v-tooltip.bottom="{
                            content: 'Add',
                            trigger: 'hover',
                          }"
                        >
                          <i
                            class="mdi mdi-plus"
                            style="
                              color: blue;
                              margin-right: 15px;
                              font-size: 20px !important;
                            "
                          ></i>
                        </button>
                      </p>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-pagination
                :total="lastPage"
                v-model="currentPage"
                @change="handleChangePage()"
              ></vs-pagination>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
      >
        <div class="row">
          <div class="col-12">
            <div>
              <vs-table
                id="featuredTable"
                :data="field.featuredData"
                :sst="true"
                :max-items="field.maxNumber"
                stripe
                noDataText="No Available Data, click to Create new ones in the top right corner."
                class="text-nowrap"
              >
                <template slot="header"> </template>
                <template slot="thead">
                  <vs-th
                    class="text-left w-30"
                    v-for="field in field.data['datatablecolumnsfeature']"
                    :key="field.id"
                  >
                    {{ field.label }} <i class="mdi mdi-sort"></i>
                  </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <draggable :list="data" tag="tbody">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td
                        :key="field"
                        class="text-left w-30"
                        v-for="(field, indextd) in data[indextr]"
                      >
                        <p
                          v-if="indextd !== data[indextr].length - 1"
                          v-html="field"
                        ></p>
                        <p v-if="indextd === data[indextr].length - 1">
                          <input
                            type="hidden"
                            class="featuredUrl"
                            name="featuredUrl[]"
                            :value="field"
                          />
                          <button
                            :href="field"
                            target="_blank"
                            class="btn btn-add"
                            @click="removeRow($event, indextr)"
                            v-tooltip.bottom="{
                              content: 'Remove',
                              trigger: 'hover',
                            }"
                          >
                            <i
                              class="mdi mdi-minus"
                              style="
                                color: red;
                                margin-right: 15px;
                                font-size: 20px !important;
                              "
                            ></i>
                          </button>
                        </p>
                      </vs-td>
                    </vs-tr>
                  </draggable>
                </template>
              </vs-table>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </vs-row>
</template>
<style>
th {
  color: white;
  padding: 6px 0 !important;
}

.vs-table--tbody-table .vs-table--thead tr {
  background: #586bb2 !important;
}

p {
  margin-bottom: 0 !important;
}

.label {
  color: #fff;
  border: none;
  cursor: default;
  display: inline-flex;
  outline: 0;
  padding: 2px 15px;
  box-sizing: border-box;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  font-weight: 400;
}

.label-info {
  background-color: #e41c42;
}

.label-success {
  background-color: #39b550;
}

.label-warning {
  background-color: #ffb300;
}

.vs-table--tbody-table .tr-values.selected {
  cursor: auto !important;
}

th.text-left.w-30:last-child i {
  display: none;
}

.btn-add {
  outline: none;
  background: transparent;
  border: none;
}

.vs-table--tbody-table .tr-values td * {
  margin-block-start: 0;
  margin-block-end: 0;
}

.vs-con-table.stripe .tr-values {
  background: #e8eaf6 !important;
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: #fff !important;
}
</style>
<script>
import draggable from "vuedraggable";

export default {
  name: "cassieldatatable",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },

  components: {
    draggable,
  },

  data: () => ({
    grid: {
      id: "datatable",
      search: true,
    },
    loading: true,
    listconfiguration: [],
    filteredData: [],
    totalItems: 0,
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    query: "",
    Orderkey: "id",
    OrderDir: "DESC",
  }),

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      const baseListConfigurationUrl = this.$apiList.baseListConfigurationApi;
      let listConfigurationUrl =
        baseListConfigurationUrl +
        "/" +
        this.field.widgetName +
        "/" +
        this.field.entityName;
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      return this.$http({
        url: listConfigurationUrl,
        method: "GET",
        headers: headers,
      }).then(async (response) => {
        this.listconfiguration = response.data;
        this.loading = false;
        this.Orderkey = this.listconfiguration.datatablecolumns[0].name;
        await this.getTableData();
      });
    },

    async getTableData() {
      let formData = new FormData();
      formData.append("page", this.currentPage);
      formData.append("length", this.perPage);
      formData.append("start", (this.currentPage - 1) * this.perPage);
      formData.append("search", this.query);
      formData.append("orderKey", this.Orderkey);
      formData.append("orderDirection", this.OrderDir);

      const baseListDataApi = this.$apiList.baseListDataApi;
      let listDataUrl =
        baseListDataApi +
        "/" +
        this.field.widgetName +
        "/" +
        this.field.entityName;
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      return this.$http({
        url: listDataUrl,
        method: "POST",
        headers: headers,
        data: formData,
      }).then((response) => {
        this.filteredData = response.data.data;
        this.totalItems = parseInt(response.data.recordsFiltered);
        this.lastPage = parseInt(this.totalItems / this.perPage + 1);
      });
    },

    addRow(e, index, url) {
      if (e !== undefined) {
        e.preventDefault();
      }
      let includes = false;
      let inputs = document.getElementsByClassName("featuredUrl");
      if (inputs.length === Number(this.maxNumber)) {
        this.$swal({
          title: "Max",
          text: "You can add only 10 records",
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#008080",
          confirmButtonText: "Ok",
        });
      } else {
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].value === url) {
            includes = true;
            break;
          }
        }
        if (includes) {
          this.$swal({
            title: "Exists",
            text: "This record already added",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#008080",
            confirmButtonText: "Ok",
          });
        } else {
          this.$set(
            this.field.featuredData,
            this.field.featuredData.length,
            this.filteredData[index]
          );
        }
      }
    },

    removeRow(e, index) {
      if (e !== undefined) {
        e.preventDefault();
      }
      this.$delete(this.field.featuredData, index);
    },
    handleSearch(searching) {
      this.query = searching;
      this.getTableData();
    },
    handleChangePage() {
      // this.currentPage = page;
      this.getTableData();
    },
    handleSort(key, active) {
      this.Orderkey = key;
      this.OrderDir = active;
      this.getTableData();
    },
  },
};
</script>
