<template>
  <div class="form-group ">
    <label class="text-monospace text-bold text-capitalize">{{ field.label }}</label>
    <div class="input-group">
      <vue-timepicker fixed-dropdown-button hide-clear-button format="h:mm a" style="width: 100%" :name="field.name"
                      v-model="field.value">
        <template v-slot:dropdownButton>
          <i class="mdi mdi-clock" style="color: black"/>
        </template>
      </vue-timepicker>
    </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "cassieltime",
  mixins: [],
  props: {
    field: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  components: {VueTimepicker},

  created() {
    console.log(this.field);
  }
};
</script>

<style>
.vue__time-picker input.display-time {
  width: 100%;
}

.vue__time-picker-dropdown .select-list, .vue__time-picker .dropdown .select-list {
  margin-top: 10px;
}
</style>