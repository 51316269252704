<template>
  <div class="form-group" v-if="field">
    <label class="text-monospace text-bold text-capitalize">
      {{ field.label }}
      <span v-if="field.required" class="text-danger">*</span>
    </label>
    <div class="input-group">
      <input
        class="form-control pull-right"
        v-model="field.value"
        :name="field.name"
        :id="field.name"
        type="datetime-local"
        style="margin-bottom: 15px"
      />
      <span
        :id="field.name + '_error'"
        class="b-toast-danger alert alert-danger cassiel-error"
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "cassieldatetime",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  created() {
    // console.log(this.field);
  },
};
</script>
