<template>
  <div class="form-group">
    <!--    <script src="@/plugins/jquery.iframe-transport.js"></script>-->
    <!--    <script src="@/plugins/jquery.fileupload.js"></script>-->
    <!--    <script src="@/plugins/jquery.fileupload-process.js"></script>-->
    <!--    <script src="@/plugins/jquery.fileupload-video.js"></script>-->
    <!--    <script src="@/plugins/jquery.fileupload-validate.js"></script>-->
    <label
      class="text-monospace text-bold text-capitalize"
      v-bind:style="field.styles"
      >{{ field.label
      }}<span v-if="field.required" class="text-danger">*</span></label
    >
    <div class="list-group">
      <div class="list-group-item">
        <div class="form-group">
          <!-- The fileinput-button span is used to style the file input field as button -->
          <div
            class="btn btn-default btn-lg fileinput-button"
            style="margin-bottom: 10px"
          >
            <i class="mdi mdi-file-pdf" style="padding: 10px 10px 10px 0"></i>
            <span>Add PDF</span>
            <!-- The file input field used as target for the file upload widget -->
            <input
              type="file"
              v-bind:name="field.name + 'pdf'"
              accept=".pdf"
              value=""
              ref="file"
              v-on:change="handleFileUpload()"
            />
            <!--            accept=".pdf"-->
          </div>
          <!-- The global progress bar -->
          <div id="progress" class="progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              v-bind:style="'width: ' + uploadPercentage + '%'"
            ></div>
          </div>
          <!-- The container for the uploaded files -->
          <div id="files" class="files" style="width: 50%"></div>
          <span class="help-block"></span>
          <input
            type="hidden"
            v-bind:name="field.name"
            v-bind:id="field.name"
            v-bind:value="field.value"
          />
          <!-- <input
            type="hidden"
            v-bind:name="'old' + field.name"
            v-bind:id="'old' + field.name"
            v-bind:value="field.value"
          /> -->
          <input
            type="hidden"
            v-bind:name="'fileSize' + field.name"
            v-bind:id="'fileSize' + field.name"
            v-bind:value="field.ileSize"
          />
          <input
            type="hidden"
            v-bind:name="'pageNumber' + field.name"
            v-bind:id="'pageNumber' + field.name"
            v-bind:value="field.pageNumber"
          />
          <p style="margin-top: 10px">{{ fileName }}</p>
          <p ref="status" style="margin-top: 10px"></p>
        </div>

        <hr />
        <div v-if="field.value !== ''">
          <h3 style="padding-bottom: 15px">Uploaded PDF</h3>
          <a
            v-bind:href="BACKEND_BASE_URL + field.value"
            download
            target="_blank"
            >Download pdf</a
          >
        </div>
        <br />
        <span
          v-bind:id="field.name + '_error'"
          class="b-toast-danger alert alert-danger cassiel-error"
          style="display: none"
        ></span>
      </div>
    </div>
  </div>
</template>
<style>
.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-default {
  background-color: #f4f4f4;
  /*background-color: transparent;*/
  color: #586bb2;
  border-color: #586bb2;
}

.h3,
h3 {
  font-size: 24px;
}

a {
  color: #3c8dbc;
}

.list-group-item {
  border-radius: 10px !important;
}

.progress-bar {
  background-color: #586bb2 !important;
}
</style>
<script>
import axios from "axios";

export default {
  name: "cassieluploadpdf",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
  },
  data() {
    return {
      file: "",
      uploadPercentage: 0,
      // newValue: "",
      fileName: "",
      BACKEND_BASE_URL: process.env.VUE_APP_PREVIEW_BASE_URL,
    };
  },

  components: {},

  computed: {},
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.submitFile();
    },
    submitFile() {
      this.uploadPercentage = 0;
      /*
        Initialize the form data
      */
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("widget", this.field.widgetName);

      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }

      /*
        Make the request to the POST /single-file URL
      */
      axios
        .post(
          process.env.VUE_APP_CMS_BACKEND_BASE_URL +
            this.$apiList.uploadFileApi,
          formData,
          {
            headers: headers,
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          }
        )
        .then(
          function (response) {
            this.field.fileSize = this.file.size;
            if (response.data.filePath === undefined) {
              this.uploadPercentage = 0;
              this.fileName = "";
              this.field.value = "";
              this.field.pageNumber = 0;
              this.$refs.status.innerHTML = "An Error Occured";
              this.$refs.status.setAttribute("class", "text-danger");
            }
            this.field.value = response.data.filePath;
            this.fileName = this.file.name;
            this.$refs.status.innerHTML = "";
            var reader = new FileReader();
            reader.readAsBinaryString(this.file);
            reader.onloadend = function () {
              this.field.pageNumber = reader.result.match(
                /\/Type[\s]*\/Page[^s]/g
              ).length;
            }.bind(this);
          }.bind(this)
        )
        .catch(
          function (error) {
            console.error(error);
            this.uploadPercentage = 0;
            this.fileName = "";
            this.field.value = "";
            this.field.pageNumber = 0;
            this.$refs.status.innerHTML = "An Error Occured";
            this.$refs.status.setAttribute("class", "text-danger");
          }.bind(this)
        );
    },
  },
};
</script>
