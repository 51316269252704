<template>
  <div style="display: flex; justify-content: space-between">
    <textarea style="display: none" name="data" :value="inputData"></textarea>

    <div class="renderedTemplate">
          <Tree :data="tree1data" draggable="draggable" cross-tree="cross-tree" @change="updateData" @drop="updateData">
            <div slot-scope="{data, store, vm}">
              <template v-if="!data.isDragPlaceHolder">
                <div class="menu-tree">
                  <div>
                    <b v-if="data.children &amp;&amp; data.children.length" @click="store.toggleOpen(data)">{{ data.open ? '-' : '+' }}&nbsp;</b>
                    <span>{{ data.name }}</span>
                  </div>
                  <div>
                    <span @click="handleEdit(data, store)"> <i class="mdi mdi-pencil"></i></span>
                    <span @click="handleDelete(data, store, vm)"> <i class="mdi mdi-delete"></i></span>
                  </div>
                </div>
              </template>
            </div>
          </Tree>
    </div>

    <div style="width: 40%">
      <div class="templates" ref="cassielLibrary" id="cassiel-library" style="top: 86px;">
        <div class="add-button">
          <button class="btn btn-primary" @click="handleOpenLightbox">Add a page</button>
        </div>

        <Tree :data="tree2data" draggable="draggable" cross-tree="cross-tree">
          <div slot-scope="{data, store}">
            <template v-if="!data.isDragPlaceHolder">
              <b v-if="data.children &amp;&amp; data.children.length" @click="store.toggleOpen(data)">{{ data.open ? '-' : '+' }}&nbsp;</b>
              <span>{{ data.name }}</span>
              <span @click="handleEdit(data, store)"> <i class="mdi mdi-pencil"></i></span>
            </template>
          </div>
        </Tree>
      </div>
    </div>

    <div class="draggable-lightbox" v-if="lightboxStatus">
      <div class="shade" @click="handleCloseLightbox"></div>
      <div class="draggable-lightbox-container">
        <div class="exit" @click="handleCloseLightbox">
          <i class="mdi mdi-close-circle-outline"></i>
        </div>
        <div>
          <input class="form-control" type="text" v-model="inputValue" placeholder="Name"/>
        </div>
        <div>
          <input class="form-control" type="text" v-model="urlValue" placeholder="Url"/>
        </div>

        <div class="add-button">
          <button v-if="addPageStatus" class="btn btn-primary" @click="handleAddButton">Add</button>
          <button v-if="editPageStatus" class="btn btn-primary" @click="handleEditButton">Edit</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {DraggableTree} from 'vue-draggable-nested-tree'

export default {
  name: "nested-draggable",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data: function () {
      return {
          inputData: '',
          entityTypes: [],
          entityTypesName: [],
          entityData: [],
          setActiveTab: '',
          lightboxStatus: false,
          inputValue: '',
          urlValue: '',
          addPageStatus: false,
          editPageStatus: false,
          tree1data: [],
          tree2data: [],
          nodeToEdit: null,
          storeEdit: null
      };
  },
  components: {
      Tree: DraggableTree
  },
  created() {
      this.inputData = this.field.data;

      this.tree1data = JSON.parse(this.field.data);
      console.log(this.tree1data)
  },
  mounted: function () {

  },
  methods: {
      handleOpenLightbox(e, node = null){
          if(e !== undefined) {
              e.preventDefault();
          }
          if(node === null) {
              this.inputValue = '';
              this.urlValue = '';
              this.addPageStatus = true;
              this.editPageStatus = false;
          }
          else{
              this.inputValue = node.name;
              this.urlValue = node.url;
              this.addPageStatus = false;
              this.editPageStatus = true;
          }
          this.lightboxStatus = true;
      },
      handleCloseLightbox(){
          this.lightboxStatus = false;
          this.inputValue = '';
          this.urlValue = '';
      },
      handleAddButton(e) {
          e.preventDefault();
          if(this.inputValue !== '' && this.urlValue !== '') {
              this.tree2data.push({ name: this.inputValue, url: this.urlValue });
              this.handleCloseLightbox();
          }
      },
      handleEdit(node, store){
          this.nodeToEdit = node;
          this.storeEdit = store;
          this.handleOpenLightbox(undefined, node);
      },
      handleEditButton(e){
          e.preventDefault();
          if(this.inputValue !== '' && this.urlValue !== '') {
              this.nodeToEdit.name = this.inputValue;
              this.nodeToEdit.url = this.urlValue;
              this.updateData(this.nodeToEdit, this.storeEdit);
              this.handleCloseLightbox();
          }
      },
      updateData(node, targetTree){
          this.inputData = JSON.stringify(targetTree.getPureData());
      },
      handleDelete(data, store, node){
          let alertMessage = "";
          if(node.$children.length > 0){
              alertMessage = "This menu has Subs. ";
          }

          this.$swal({
              title: "Are you sure you want to 'DELETE' this record?",
              text: alertMessage + "You will not be able to recover it after you Save/Publish!",
              confirmButtonText: "yes",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonColor: "#008080",
              customClass: {
                  title: "error",
                  confirmButton: "confirm-button",
              },
          }).then((result) => {
              if (result.isConfirmed) {
                  store.deleteNode(data);
                  this.updateData(node, store);
              }
          });
      },
  },
};
</script>

<style>
.renderedTemplate {
  width: 58%;
  float: left;
}
.menu-tree{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}
.draggable-lightbox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.draggable-lightbox .shade{
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.draggable-lightbox .draggable-lightbox-container{
  background: #FFF;
  padding: 30px;
  position: relative;
  z-index: 2;
}
.draggable-lightbox .exit{
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  cursor: pointer;
}
.draggable-lightbox input{
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  direction: rtl
}
.add-button button{
  margin-right: 0;
}

.he-tree {
  border: 1px solid #ccc;
  padding: 20px;
}

.tree-node-inner {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.draggable-placeholder-inner {
  border: 1px dashed #0088f8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}

</style>
