<template>
  <input
      type="hidden"
      :name="field.name"
      v-model="field.value"
      :id="field.name"
      class="form-control"
  />
</template>

<script>
export default {
  name: "cassielhiddenfield",
  mixins: [],
  props: {
    field: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  created(){
    // console.log(this.field);
  }
};
</script>