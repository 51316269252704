<template>
  <div style="margin-bottom: 19px">
    <label class="text-monospace text-bold text-capitalize"
      >{{ field.label }}
      <span v-if="field.required" class="text-danger">*</span>
    </label>
    <div>
      <label style="display: flex; align-items: center;">
        <input
                style="position: relative; margin-right: 10px; height: inherit;"
                type="checkbox"
                v-model="originalImageRatioStatus"
        /> keep original image ratio
      </label>
    </div>
    <div class="img-container">
      <div class="img-input-container">
        <div class="input-group">
          <span
            :id="'error_' + field.widgetName + '|' +  ( (custom==='true')?name:field.name)"
            class="text-danger b-toast-danger"
          />
          <div class="custom-file">
            <input
              type="file"
              :aria-describedby="
                'imageUploadSpan-' +
                field.widgetName +
                '-' +
                   ( (custom==='true')?parentIndex+'-'+index:field.index)  +
                '-' +
                field.name
              "
              accepts="image/*"
              :id="
                'imageUpload-' +
                field.widgetName +
                '-' +
                 ( (custom==='true')?parentIndex+'-'+index:field.index)   +
                '-' +
                field.name
              "
              @change="croppie"
              hidden
            />
            <label
              class="custom-file-label"
              :for="
                'imageUpload-' +
                field.widgetName +
                '-' +
                 ( (custom==='true')?parentIndex+'-'+index:field.index)  +
                '-' +
                field.name
              "
            >
              <span v-if="field.fileName !== ''">
                {{ field.fileName }}
              </span>
              <span v-else> Choose file </span>
              <div class="uploadButton">
                <i class="mdi mdi-cloud-download"></i>
                Upload File
              </div>

              <div class="uploadStatus" :class="{ success: uploadStatus }">
                {{ uploadStatusText }}
              </div>
            </label>
          </div>
        </div>
        <div class="form-group" style="padding-top: 15px">
          <input
            type="text"
            placeholder="Image alt text"
            :id="
              'alt-' + field.widgetName + '-' +  ( (custom==='true')?parentIndex+'-'+index:field.index)  + '-' + field.name
            "
            class="form-control"
            :value="field.alt"
            @blur="imageAltUpload($event.target.value)"
          />
        </div>
      </div>
      <div class="img-thumbnail" v-if="field.value !== ''">
        <a target="_blank" :href="field.value"><img :src="field.value" /></a>
      </div>
    </div>

    <div style="margin-top: 10px">
      <div class="row" style="margin-bottom: 0 !important">
        <!-- <p>{{ field.fileName }}</p> -->
        <!-- <a
          target="_blank"
          :href="field.fileUrl"
          v-if="fileUrl !== ''"
          style="margin: 0 10px"
          v-tooltip.bottom="{ content: 'Image Link', trigger: 'hover' }"
        >
          <i
            class="mdi mdi-link"
            style="font-size: 20px !important; color: teal"
          ></i>
        </a> -->
      </div>
      <button
        v-if="field.value !== '' || cropped !== ''"
        class="cropie-btn button button-outline-gray w-fixed"
        @click="showhidecropie"
      >
        <span v-if="showcropie">Hide Cropie</span>
        <span v-else>Show Cropie</span>
      </button>
      <button
        v-if="field.value !== '' || cropped !== ''"
        class="cropie-btn button w-fixed"
        @click="crop"
      >
        Save
      </button>
      <button
              v-if="field.value !== '' || cropped !== ''"
              class="btn btn-transparent btn-left-border btn-rounded"
              @click="handleDelete"
      >
        Delete
      </button>
      <span ref="status"> </span>
      <vue-croppie
        v-show="showcropie"
        :ref="'croppieRef' + subIndex"
        :enforceBoundary="true"
        :enableResize="false"
        :boundary="boundaryObject"
        :viewport="viewportObject"
        :maxZoomedCropWidth="400"
        :minZoom="0.8"
        :maxZoom="3"
        @update="update"
      />
      <input
        type="hidden"
        :name="field.widgetName + '|' +  ( (custom==='true')?name:field.name)"
        v-model="field.value"
        :id="field.widgetName + '-' +  ( (custom==='true')?parentIndex+'-'+index:field.index) + '-' + '|' + field.name"
        class="form-control"
      />
    </div>
    <div>
      <span
        :id="'error_' + field.widgetName + '|' +  ( (custom==='true')?name:field.name)"
        class="b-toast-danger alert alert-danger cassiel-error"
        style="display: none"
      ></span>
    </div>
  </div>
</template>

<style>
.img-container {
  display: flex;
}
.img-input-container {
  flex: 1;
  max-width: 58%;
}
.img-input-container input,
.img-input-container .custom-file {
  max-width: 100%;
}
.img-thumbnail {
  position: relative;
  max-height: 91px;
  margin-left: 10px;
}
.img-thumbnail img {
  height: 100%;
}
.btn-upload {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0;
  margin: 10px;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 42px;
  height: 42px;
  text-align: center;
  background: linear-gradient(40deg, #2096ff, #05ffa3) !important;
  margin-top: 0 !important;
  color: white;
  line-height: 42px;
  font-size: 1.7rem;
}

.cropie-btn {
  margin: 10px 10px 10px 0;
}
.custom-file-label {
  border: 1px solid #c1c6e5;
  color: #c1c6e5;
  font-size: 16px;
  font-weight: 300 !important;
  padding: 8px 18px 5px 195px;
  border-radius: 5px !important;
  height: 42px;
  margin: 0;
}
.uploadButton {
  position: absolute;
  z-index: 9;
  color: #202228;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  background: #e8eaf6;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 41px 0 14px;
  display: flex;
  align-items: center;
}
.uploadButton i {
  font-size: 28px;
  margin-right: 11px;
  color: #586bb2;
}

.custom-file-label:after {
  content: none;
}
.uploadStatus {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #e41c42;
  font-size: 16px;
  font-weight: 300;
}
.uploadStatus.success {
  color: #39b550;
}
.croppie-container {
  margin-top: 15px;
}
</style>

<script>
export default {
    name: "cassielcropie",
    components: {},
    props: {
        field: {
            type: Object,
        },
        index: {
            type: Number,
        },
        parentIndex: {
            type: Number,
        },
        custom: {
            type: String,
            default: 'false'
        },
        name: {
            type: String
        }
    },
    data() {
        return {
            values: [],
            croppieImage: "",
            cropped: null,
            imageBaseUrl: "",
            showcropie: false,
            boundary: Object(),
            fileUrl: "",
            uploadStatus: false,
            uploadStatusText: "",
            croppedStatus: false,
            updateStatus: 0,
            subIndex: 0,
            originalImageRatioStatus: false,
            fieldWidth: 0,
            fieldHeight: 0,
            maxViewPortWidth: 600,
            maxViewPortHeight: 600,
        };
    },
    computed: {
        boundaryObject() {
            let boundaryob = Object();
            boundaryob.width = this.field.width * 1.1;
            boundaryob.height = this.field.height * 1.1;
            return boundaryob;
        },
        viewportObject() {
            let boundaryob = Object();
            boundaryob.width = this.field.width;
            boundaryob.height = this.field.height;
            return boundaryob;
        },
    },
    mounted: function () {
        this.subIndex = this.index;
        this.imageBaseUrl = process.env.VUE_APP_FILES_BASE_URL;
        this.cropped = this.field.value;

        this.uploadStatus = false;
        this.uploadStatusText = "not saved";

        this.field.index = this.index;
        if (this.parentIndex !== undefined) {
            this.field.index = this.parentIndex + "_" + this.index;
        }
        if (this.custom === 'true') {
            this.field.index = this.parentIndex + "_" + this.index;
            this.field.name = this.name;
            this.subIndex = this.parentIndex + "_" + this.index;
        }
    },
    watch: {
        "field.value": function (newVal, oldVal) {
            if (
                newVal !== oldVal &&
                this.$draggableValue &&
                Number(this.$draggableValue) === 1
            ) {
                this.$draggableValue = 0;
                this.cropped = newVal;
                this.field.value = newVal;
                this.$refs["croppieRef" + this.subIndex].refresh();
                this.$refs["croppieRef" + this.subIndex].bind({
                    url: this.imageBaseUrl + "" + this.cropped,
                    boundary: this.boundary,
                    viewportRect: this.boundary,
                });
            } else {
                this.$draggableValue = 1;
            }
        }
    },
    methods: {
        update() {
            this.updateStatus += 1;
            if (this.updateStatus === 2) {
                this.crop();
            }
        },

        async croppie(e) {
            this.showcropie = true;
            this.croppedStatus = true;
            this.$draggableValue = 0;
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length) return;

            let reader = new FileReader();
            this.field.fileName = files[0].name;
            this.field.extension = files[0].type;
            this.field.binary = files[0];

            if(this.originalImageRatioStatus) {
                this.fieldWidth = this.field.width;
                this.fieldHeight = this.field.height;
                const imgDimentions = await this.getImageSize(files);

                let newWidth = imgDimentions.width;
                let newHeight = imgDimentions.height;
                let aspectRatio = newWidth / newHeight;

                if(aspectRatio > 1) {
                    if(newWidth > this.maxViewPortWidth) {
                        newWidth = this.maxViewPortWidth;
                        newHeight = Math.ceil((imgDimentions.height / imgDimentions.width) * this.maxViewPortWidth);
                    }
                }
                else{
                    if(newHeight > this.maxViewPortHeight) {
                        newHeight = this.maxViewPortHeight;
                        newWidth = Math.ceil((imgDimentions.width / imgDimentions.height) * this.maxViewPortHeight);
                    }
                }

                this.field.width = newWidth;
                this.field.height = newHeight;
            }
            else{
                if(this.fieldWidth !== 0){
                    this.field.width = this.fieldWidth;
                    this.field.height = this.fieldHeight;
                }
            }

            reader.onload = (e) => {
                this.$refs["croppieRef" + this.subIndex].refresh();
                this.$refs["croppieRef" + this.subIndex].bind({
                    url: e.target.result,
                    boundary: this.boundary,
                    viewportRect: this.boundary,
                });
                this.updateStatus = 0;
            };

            // this.$refs.status.innerHTML = ' saved';
            reader.readAsDataURL(files[0]);
        },

        async getImageSize(files) {
            this.file = files[0];
            const photoUrl = URL.createObjectURL(this.file);
            const image = new Image();
            const imageDimensions = await new Promise((resolve) => {
                image.onload = () => {
                    const dimensions = {
                        height: image.height,
                        width: image.width,
                    };
                    resolve(dimensions);
                };
                image.src = photoUrl;
            });
            return imageDimensions;
        },
        crop(e) {
            if (e !== undefined) {
                e.preventDefault();
            }

            if (this.field.extension === "image/gif") {
                this.saveBlobAjax(this.field.binary);
                return;
            }

            // Options can be updated.
            // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
            let options = {
                type: "blob",
                size: {width: this.field.width, height: this.field.height},
                format: "jpeg",
            };

            this.$refs["croppieRef" + this.subIndex].result(options, (output) => {
                this.cropped = this.croppieImage = output;
                this.saveBlobAjax(output);
            });
        },

        handleDelete(e) {
            e.preventDefault();
            this.$swal({
                title: "Are you sure you want to 'DELETE' this image?",
                text: "Your will not be able to recover it after you Save/Publish!",
                confirmButtonText: "yes",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: "#008080",
                customClass: {
                    title: "error",
                    confirmButton: "confirm-button",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.field.value = '';
                }
            });
        },
        showhidecropie(e) {
            e.preventDefault();
            this.showcropie = !this.showcropie;
            if (this.croppedStatus === false) {
                this.$refs["croppieRef" + this.subIndex].refresh();
                this.$refs["croppieRef" + this.subIndex].bind({
                    url: this.cropped,
                    boundary: this.boundary,
                    viewportRect: this.boundary,
                });
                this.croppedStatus = true;
            }
        },
        saveBlobAjax(output) {
            const token = this.$store.getters["auth/getToken"];
            let headers = {};
            if (token) {
                headers["X-AUTH-TOKEN"] = token;
            }

            if (this.cropped !== undefined) {
                let data = new FormData();

                data.append("file", output);
                data.append("width", this.field.width);
                data.append("height", this.field.height);
                data.append("filter", this.field.filter);
                data.append("widget", this.field.widgetName);
                data.append("alt", this.field.alt);
                // this.$refs.status.innerHTML = "Cropping";

                this.uploadStatus = false;
                this.uploadStatusText = "cropping";

                this.$http({
                    url: this.$apiList.uploadFileApi,
                    method: "POST",
                    data: data,
                    headers: headers,
                })
                    .then((response) => {
                        if (response.data.filePath === undefined) {
                            this.$refs.status.innerHTML = "An Error Occured";
                            this.$refs.status.setAttribute("class", "text-danger");
                            return;
                        }

                        let image = response.data.filePath;

                        this.cropped = image;
                        this.uploadStatus = true;
                        this.uploadStatusText = "saved";
                        // this.$refs.status.innerHTML = "Saved";
                        // this.$refs.status.setAttribute("class", "text-teal");
                        this.field.value = image;
                        this.fileUrl = process.env.VUE_APP_FILES_BASE_URL + image;
                    })
                    .catch((error) => {
                        this.$refs.status.innerHTML = "An Error Occured : " + error;
                        this.$refs.status.setAttribute("class", "text-danger");
                    });
            } else {
                alert("Input something!");
            }
        },
        imageAltUpload: function (value) {
            let url = document.getElementById(
                this.field.name + this.subIndex + "Old"
            ).value;
            if (url === "") return;

            let data = new FormData();
            data.append("url", url);
            data.append("alt", value);

            const token = this.$store.getters["auth/getToken"];
            let headers = {};
            if (token) {
                headers["X-AUTH-TOKEN"] = token;
            }
            this.uploadStatus = false;
            this.uploadStatusText = "cropping";
            // this.$refs.status.innerHTML = "Cropping";
            this.$http({
                url: this.$apiList.updateImageInfo,
                method: "POST",
                data: data,
                headers: headers,
            })
                .then((response) => {
                    if (!response.data.error) {
                        this.$refs.status.innerHTML = response.data.message;
                        this.$refs.status.setAttribute("class", "text-teal");
                    } else {
                        this.$refs.status.innerHTML = "An Error Occured";
                        this.$refs.status.setAttribute("class", "text-danger");
                    }
                })
                .catch((error) => {
                    this.$refs.status.innerHTML = "An Error Occured : " + error;
                    this.$refs.status.setAttribute("class", "text-danger");
                });
        },
    },
};
</script>
