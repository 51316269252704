<template>
  <div class="form-group">
    <label
      class="text-monospace text-bold text-capitalize"
      :style="field.styles"
    >
      {{ field.label }}
      <span v-if="field.required" class="text-danger"> * </span>
    </label>
    <input
      v-model="field.value"
      type="number"
      :name="field.name"
      class="form-control"
      :placeholder="field.placeholder"
    />
    <br />
    <span
      :id="field.name + '_error'"
      class="b-toast-danger alert alert-danger cassiel-error"
      style="display: none"
    />
  </div>
</template>

<script>
export default {
  name: "cassielnumberfield",
  mixins: [],
  props: {
    field: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
};
</script>
